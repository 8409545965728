import React, {useEffect} from 'react'
import {Backdrop, Fade, makeStyles, Modal} from "@material-ui/core"
import { useHistory } from 'react-router-dom'
import Login from './Login'

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        // border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}))

const Auth = () => {
    const classes = useStyles()
    const [open, setOpen] = React.useState(false)
    const history = useHistory()

    useEffect(() => {
        handleOpen()
    }, [])

    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
        history.goBack()
    }

    const loginForm = (
        <div className={classes.paper}>
            <h2 id="transition-modal-title">Autorizovaný přístup</h2>
            <Login handleClose={handleClose} />
        </div>
    )


    return (
        <>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                closeAfterTransition
                disableBackdropClick={true}
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    {loginForm}
                </Fade>
            </Modal>
        </>
    )
}

export default Auth
