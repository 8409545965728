import React, {Component} from 'react'
import {withRouter, useParams} from 'react-router-dom'
import _ from 'lodash'
import ContextProvider, {AppContext} from '../ContextProvider'
import Axios from 'axios'
import PinGreen from "../assets/pinGreen.svg"
import PinGray from '../assets/pinGray.svg'
import PinRed from '../assets/pinRed.svg'
import User from '../assets/user.svg'
import SearchInput from '../panels/SearchInput'
import queryString from 'query-string'
import {makeStyles, withStyles} from '@material-ui/core/styles'
import Button from "@material-ui/core/Button";
import {compose} from 'recompose'
import axios from "axios";

const styles = theme => ({
    greenButton: {
        background: '#32702F',
        border: 1,
        borderRadius: 3,
        color: '#FFFFFF',
        height: 48,
        width: '100%',
        '&:hover': {
            background: 'green',
        },
        margin: '0 0 5px 0',
    },

})

class MapAdmin extends Component {

    static contextType = AppContext

    map = null
    markers = []
    message = ''

    constructor(props) {
        super(props)

        this.state = {
            editingMode: false,
            pin: PinGreen
        }
    }

    componentDidMount() {
        this.loadGoogleMapScript()
    }

    componentDidUpdate() {
        const ctx = this.context
        if (this.message !== ctx.state.message) {
            this.message = ctx.state.message
            console.log('UPDATE SEARCH')
            fetch(`${process.env.REACT_APP_API}/maps/search/${ctx.state.message}`)
                .then(res =>
                    res.json()
                )
                .then(json => {
                    const latLng = new window.google.maps.LatLng(json[1], json[0])
                    this.map.setZoom(16)
                    this.map.setCenter(latLng)
                })
        }
        this.hitMarker()
    }

    loadGoogleMapScript() {
        if (window.google !== undefined) {
            return this.initMap()
        }

        const ApiKey = process.env.REACT_APP_GOOGLE_MAPS_KEY

        const script = window.document.createElement('script')
        script.src = `https://maps.googleapis.com/maps/api/js?key=${ApiKey}&language=cs&libraries=geometry`
        script.async = true
        script.defer = true
        script.onerror = function () {
            window.alert("Selhalo načtení Google mapy.")
        }
        script.addEventListener('load', () => {
            this.initMap()
        });
        window.document.body.appendChild(script)
    }

    async initMap() {
        const map = new window.google.maps.Map(document.getElementById('map'), {
            center: {lat: 49.81749199999999, lng: 15.472962},
            zoom: 16,
            minZoom: 14,
            controlSize: 28,
            mapTypeControl: true,
            clickableIcons: false,
            streetViewControl: false,
            options: {
                gestureHandling: 'greedy',
                fullscreenControl: false
            },
            mapTypeControlOptions: {
                mapTypeIds: ['roadmap', 'satellite', 'hybrid', 'terrain', 'styled_map'],
                style: window.google.maps.MapTypeControlStyle.DEFAULT,
                position: window.google.maps.ControlPosition.TOP_LEFT
            }
        })
        this.map = map
        map.mapTypes.set('styled_map', new window.google.maps.StyledMapType(this.props.mapStyle, {name: 'Zelená mapa'}))
        map.setMapTypeId('styled_map')

        const geolocationDiv = document.createElement('div')
        this.geolocationControl(geolocationDiv, map)

        let isUserLocation = true
        let urlPosition
        const params = queryString.parse(this.props.location.search)

        if (params.lat !== undefined && params.lng !== undefined && params.zoom !== undefined) {
            let params = queryString.parse(this.props.location.search)
            urlPosition = new window.google.maps.LatLng(Number(params.lat), Number(params.lng))
            const zoom = Number(params.zoom)
            this.map.setCenter(urlPosition)
            this.map.setZoom(zoom)
            isUserLocation = false
        }

        await this.geoLocateUser(isUserLocation, urlPosition)

        map.addListener('idle', () => {
            this.state.editingMode ? this.updateEditLocation() : this.updateMap()
        })
    }

    async updateEditLocation() {
        let pos = this.map.getCenter()
        const ctx = this.context

        ctx.setCoordinates(pos.lat(), pos.lng())

        try {
            const {data} = await Axios.get(`${process.env.REACT_APP_API}/api/markers/get/address`, {
                params: {
                    lat: pos.lat(),
                    lng: pos.lng(),
                }
            })
            document.querySelector("#address").innerHTML = data.name + '<br />' + data.postalCode + ' ' + data.city
        } catch (e) {
            document.querySelector("#address").innerHTML = "Nenalezena adresa.<br>Prosím neměňte pozici mapy rychle za sebou, <br>geocoder má omezení na počet požadavků v čase."
        }
    }

    async updateMap() {
        if (this.state.editingMode) return

        console.log('UPDATE MAP')
        const lat = this.map.getCenter().lat()
        const lng = this.map.getCenter().lng()
        const search = this.props.location.search
        const currentSearch = "?" + new URLSearchParams({
            lat: lat.toString(),
            lng: lng.toString(),
            zoom: this.map.getZoom()
        })
        if (search !== currentSearch) {
            const {pathname} = this.props.location
            this.props.history.replace({
                pathname: pathname,
                search: currentSearch
            })
        }

        await this.props.fetchMarkers(this.map.getBounds(), this.map.getZoom())
        this.addMarkers(this.props.markers)
    }

    hitMarker() {
        console.log('HIT MARKER')

        const ctx = this.context
        const place = ctx.state.place
        const action = ctx.state.placeAction
        const marker = _.find(this.markers, {key: place})

        console.log(action)

        switch (action) {
            case ContextProvider.ActionsEnum.OVER:
                if (marker && marker.getAnimation() == null) {
                    marker.setAnimation(window.google.maps.Animation.BOUNCE)
                }
                break
            case ContextProvider.ActionsEnum.OUT:
                _.forEach(this.markers, match => {
                    match.setAnimation(null)
                })
                break
            case ContextProvider.ActionsEnum.CLICK:
                if (!marker) {
                    return
                }
                document.querySelector("#address").innerHTML = ''
                ctx.setPlace(place, ContextProvider.ActionsEnum.UNDEFINED)
                this.setState({
                    editingMode: true,
                    pin: marker.isHidden ? PinGray : marker.isVerified ? PinGreen : PinRed
                })

                this.markers.forEach(marker => {
                    marker.setMap(null)
                })
                this.markers = []

                this.map.setCenter(marker.getPosition())
                this.props.history.push(`/admin/misto/${place}`)

                this.map.setMapTypeId('satellite')
                this.map.heading = 90
                this.map.tilt = 45
                this.map.setZoom(20)
                break
            case ContextProvider.ActionsEnum.BACK:
                ctx.setPlace(place, ContextProvider.ActionsEnum.UNDEFINED)
                this.setState({
                    editingMode: false
                })
                this.map.setMapTypeId('styled_map')
                this.map.setZoom(16)
            default:
                break
        }
    }

    async addNew() {
        document.querySelector("#address").innerHTML = ''
        this.setState({
            editingMode: true,
            pin: PinGreen
        })
        this.markers.forEach(marker => {
            marker.setMap(null)
        })
        this.markers = []

        this.props.history.push(`/admin/nove`)

        this.map.setMapTypeId('satellite')
        this.map.heading = 90
        this.map.tilt = 45
        this.map.setZoom(20)
    }

    addMarkers(markersData) {
        console.log("ADD MARKERS")

        if (this.map == null) return

        if (markersData.length > 0) {
            const redundant = this.markers.filter(val => !markersData.some(data => data._id === val.key))
            redundant.forEach(val => val.setMap(null))
            this.markers = this.markers.filter(val => markersData.some(data => data._id === val.key))

            markersData = markersData.filter(val => !this.markers.some(data => data.key === val._id))
        }

        markersData.forEach(data => {
            const marker = this.createMarker(data)
            marker.setMap(this.map)
            this.markers.push(marker)
        })
    }

    createMarker(markerData) {
        const icon = {
            url: markerData.isHidden ? PinGray : markerData.isVerified ? PinGreen : PinRed,
            anchor: new window.google.maps.Point(15, 30),
            scaledSize: new window.google.maps.Size(30, 30)
        }
        const marker = new window.google.maps.Marker({
            position: new window.google.maps.LatLng(markerData.lat, markerData.lng),
            icon: icon,
            draggable: false,
            optimized: false,
            clickable: true,
            zIndex: +new Date,
            key: markerData._id,
            isHidden: markerData.isHidden,
            isVerified: markerData.isVerified
        })
        const ctx = this.context

        marker.addListener('click', async () => {
            ctx.setPlace(markerData._id, ContextProvider.ActionsEnum.CLICK)
        })

        return marker
    }

    getCurrentLocation(options) {
        return new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resolve, ({code, message}) =>
                    reject(Object.assign(new Error(message), {name: 'GeoPositionError', code})),
                options)
        })
    }

    async geoLocateUser(showPosition, urlPosition) {
        try {
            const position = await this.getCurrentLocation({
                enableHighAccuracy: true,
                timeout: 5000,
                maximumAge: 0
            })
            const userPosition = new window.google.maps.LatLng(position.coords.latitude, position.coords.longitude)
            const svgAnimated = btoa([
                '<?xml version="1.0"?>',
                '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">',
                '<animate href="#back" attributeName="r" from="5.3" to="10" dur="1.2s" begin="0s" repeatCount="indefinite" fill="freeze" id="circ-anim"/>',
                '<animate href="#back" attributeType="CSS" attributeName="opacity" from="1" to="0" dur="1.2s" begin="0s" repeatCount="indefinite" fill="freeze" id="circ-anim" />',
                '<circle id="back" cx="10" cy="10" r="6.5" stroke-width="1" fill="MediumSeaGreen"/>',
                '<circle class="front" cx="10" cy="10" r="5.5" fill="MediumSeaGreen" />',
                '</svg>'
            ].join('\n'))

            this.locationMarker = new window.google.maps.Marker({
                position: userPosition,
                map: this.map,
                clickable: false,
                icon: {
                    url: 'data:image/svg+xml;charset=UTF-8;base64,' + svgAnimated,
                    anchor: new window.google.maps.Point(25, 25),
                    scaledSize: new window.google.maps.Size(50, 50)
                },
                optimized: false,
                zIndex: 1000
            })
            if (showPosition) {
                this.map.setCenter(userPosition)
            } else {
                this.map.setCenter(urlPosition)
            }
        } catch (e) {
            if (e.name === 'GeoPositionError') {
                window.alert('Nepodařilo se získat Vaši geolokaci. Zkontrolujte, zda ji máte povolenou.\n Chyba: ' + e.message) //+ ' ' + e.code)
            }
        }
    }

    geolocationControl(controlDiv, map) {
        const firstChild = document.createElement('button')
        firstChild.style.backgroundColor = '#fff'
        firstChild.style.border = 'none'
        firstChild.style.outline = 'none'
        firstChild.style.width = '28px'
        firstChild.style.height = '28px'
        firstChild.style.borderRadius = '2px'
        firstChild.style.boxShadow = '0 1px 4px rgba(0,0,0,0.3)'
        firstChild.style.cursor = 'pointer'
        firstChild.style.marginRight = '8px'
        firstChild.style.padding = '0'
        firstChild.title = 'Vaše poloha'
        controlDiv.appendChild(firstChild)

        var secondChild = document.createElement('div')
        secondChild.style.margin = '5px'
        secondChild.style.width = '18px'
        secondChild.style.height = '18px'
        secondChild.style.backgroundImage = 'url(https://maps.gstatic.com/tactile/mylocation/mylocation-sprite-2x.png)'
        secondChild.style.backgroundSize = '180px 18px'
        secondChild.style.backgroundPosition = '6 6'
        secondChild.style.backgroundRepeat = 'no-repeat'
        firstChild.appendChild(secondChild)

        window.google.maps.event.addListener(map, 'center_changed', function () {
            secondChild.style['background-position'] = '0 0'
        })

        controlDiv.index = 1
        map.controls[window.google.maps.ControlPosition.RIGHT_BOTTOM].push(controlDiv)

        firstChild.addEventListener("click", async () => {
            try {
                const position = await this.getCurrentLocation({
                    enableHighAccuracy: true,
                    timeout: 1000 * 60 * 60,
                    maximumAge: 0
                })
                const userPosition = new window.google.maps.LatLng(position.coords.latitude, position.coords.longitude)
                map.setCenter(userPosition)
                this.updateMap()
            } catch (e) {
                if (e.name === 'GeoPositionError') {
                    window.alert('Nepodařilo se získat Vaši geolokaci.\n Chyba: ' + e.message) //+ ' ' + e.code)
                }
            }
        })
    }

    logout() {
        const answer = window.confirm("Opravdu se chcete odhlásit?")
        if (answer) {
            localStorage.removeItem('user')
            localStorage.removeItem('token')
            window.location.href = "/uzivatel/prihlaseni"
        }
    }

    render() {
        const { classes } = this.props
        return (
            <>
                <div id="searchWrapper" className={this.state.editingMode ? 'hidden' : ''}>
                    <SearchInput/>
                </div>
                <div id="map-wrapper">
                    <div id="map"/>
                    <div id="pinLocation" className={this.state.editingMode ? '' : 'hidden'}>
                        <img src={this.state.pin}/>
                    </div>
                    <div id="address" className={this.state.editingMode ? '' : 'hidden'}/>
                    <div id="newMarkerButton" className={this.state.editingMode ? 'hidden' : ''}>
                        <Button variant="contained" className={classes.greenButton} onClick={() => this.addNew()}>Přidat sběrné místo</Button>
                        {/*<button className="buttonHelp" onClick="help()">Návod</button>*/}
                    </div>
                    <div id="loginInfo">
                        <img src={User} id="user" />
                            <div id="userInfo">
                                <strong>{localStorage.getItem('user')}</strong> <a onClick={() => this.logout()} style={{cursor: 'pointer'}}>Odhlásit se.</a>
                            </div>
                    </div>
                </div>
            </>
        )
    }
}


//export default withRouter(MapAdmin)
export default compose(
    withRouter,
    withStyles(styles)
)(MapAdmin)
