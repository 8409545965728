import React, {Component} from 'react'
import Axios from 'axios'
import NavigationAdmin from './admin/NavigationAdmin'
import MapAdmin from './admin/MapAdmin'
import {Route, withRouter} from "react-router-dom"
import './css/App.css'
import Layout from './Layout'
import CssBaseline from '@material-ui/core/CssBaseline'

import {withStyles} from '@material-ui/core/styles'
import Cookies from "./cookies/Cookies";

class Admin extends Component {

    constructor(props) {
        super(props)

        this.state = {
            markers: []
        }

        Axios.interceptors.request.use(
            config => {
                const token = localStorage.getItem('token')
                config.headers.authorization = `${token}`
                return config
            },
            error => {
                return Promise.reject(error);
            }
        )

        Axios.interceptors.response.use(response => {
            return response
        }, error => {
            if (401 === error.response.status) {
                window.location.href = "/uzivatel/prihlaseni?m=Z důvodu nečinnosti proběhlo odhlášení."
            } else {
                return Promise.reject(error)
            }
        })

        this.fetchMarkers = this.fetchMarkers.bind(this)
    }

    async fetchMarkers(bounds, zoom) {
        console.log('FETCH')
        const southWest = bounds.getSouthWest()
        const northEast = bounds.getNorthEast()
        try {
            const {data} = await Axios.get(`${process.env.REACT_APP_API}/maps/markers/edit`, {
                params: {
                    southWestLng: southWest.lng(),
                    southWestLat: southWest.lat(),
                    northEastLng: northEast.lng(),
                    northEastLat: northEast.lat(),
                    zoom: zoom
                }
            })
            this.setState({
                markers: data
            })
        } catch (e) {
        }

    }

    render() {
        return (
            <>
                <CssBaseline/>
                <Layout>
                    <Route exact path="/admin">
                        <NavigationAdmin markers={this.state.markers}/>
                    </Route>
                    <MapAdmin
                        mapStyle={require('./staticData/styledMapType.json')}
                        markers={this.state.markers}
                        fetchMarkers={this.fetchMarkers}
                    />
                </Layout>
                <Cookies/>
            </>
        )
    }
}

export default Admin/////withStyles(styles)(Admin)
