import React, { useContext, useEffect } from 'react'
import { useHistory } from "react-router-dom"
import ContextProvider, { AppContext } from '../ContextProvider'
import PinGreen from "../assets/pin.svg"
import PinGray from '../assets/pinGray.svg'
import PinRed from '../assets/pinRed.svg'

const NavigationAdmin = (props) => {
    const history = useHistory()
    const context = useContext(AppContext)

    const onMouse = (placeId, action) =>
        context.setPlace(placeId, action)

    const location = (position) => {
        let str = ''
        if(position.name.length > 0) {
            str = position.name + ', '
        }
        if (position.postalCode === undefined) {
            str += position.city
        } else {
            str += position.postalCode + ' ' + position.city
        }

        return str
    }

    return (
        <div id="markers">
            {props.markers.map(({ _id, position, isHidden, isVerified }) =>
                <div key={_id} className="menuItem"
                    onClick={() => {
                        onMouse(_id, ContextProvider.ActionsEnum.CLICK)
                        //history.push(`/admin/misto/${_id}`)
                    }}
                    onMouseOver={() => { onMouse(_id, ContextProvider.ActionsEnum.OVER) }}
                    onMouseOut={() => { onMouse(_id, ContextProvider.ActionsEnum.OUT) }}
                >
                    <p>{location(position)}</p>
                    <img src={isHidden ? PinGray : isVerified ? PinGreen : PinRed} />
                    <div className="clearfix" />
                </div>
            )}
        </div>
    )
}

export default NavigationAdmin
