import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import $ from 'jquery'

export default ({ id, panel }) => {

    const history = useHistory()

    useEffect(() => {
        const paddingLeft = $(`#${panel}`).css('padding-left')
        $('.panel__header').css('padding', `20px 20px 0 ${paddingLeft === '5px' ? '20px' : paddingLeft}`)
        //$('.panel__header').css({'padding': `20px 20px 0 ${$(`#${panel}`).css('padding-left')}`})
    }, [])

    return (
        <div className="panel__header" onClick={() => history.goBack()}>
            <div className="panel__headerTitle title">{id}</div>
            <div className="panel__headerBack" />
            <div className="clearfix" />
        </div>
    )
}
