import React, {useState} from 'react'
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import {makeStyles} from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Logo from '../assets/logo.svg'
import axios from "axios";
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';
import {CircularProgress} from "@material-ui/core";
import {AlertTitle} from "@material-ui/lab";
import {useLocation} from "react-router-dom"

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'© '}
            <Link color="inherit" href="https://material-ui.com/">
                kamtridit.cz
            </Link>{' '}
            {new Date().getFullYear()}
        </Typography>
    )
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: 'black',
        paddingTop: 5,
        paddingBottom: 0,
        paddingRight: 30,
        paddingLeft: 30,
        width: '100%',
        marginBottom: 30,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        height: 50,
    },
    alert: {
        width: '100%',
        marginTop: theme.spacing(2),
    },
    loader: {
        marginTop: 20,
    },
    link: {
        cursor: 'pointer',
    }
}))

export default function Confirmation() {
    const classes = useStyles()
    const search = useLocation().search
    const message = new URLSearchParams(search).get('message')
    const status = new URLSearchParams(search).get('status')

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <div className={classes.paper}>
                <div className={classes.avatar}>
                    <img src={Logo}/>
                </div>
                <Typography component="h1" variant="h5">
                    POTVRZENÍ REGISTRACE
                </Typography>
                <>
                    <Alert severity={message != null ? status === '400' ? 'info' : 'warning' : 'success'}
                           className={classes.alert}>
                        {message != null &&
                        <AlertTitle>{message}</AlertTitle>
                        }
                        {message != null && status === '400' &&
                        <>
                            <Link to="" className={classes.link}
                                  onClick={() => window.location.href = "/uzivatel/prihlaseni"} variant="body2">
                                Můžete se přihlásit.
                            </Link>
                        </>
                        }
                        {message == null &&
                        <>
                            <AlertTitle>Děkujeme za potvrzení vašeho e-mailu.</AlertTitle>
                            <Link to="" className={classes.link}
                                  onClick={() => window.location.href = "/uzivatel/prihlaseni"} variant="body2">
                                Můžete se přihlásit.
                            </Link>
                        </>
                        }
                    </Alert>
                    <Box mt={8}>
                        <Copyright/>
                    </Box>
                </>
            </div>

        </Container>
    )
}