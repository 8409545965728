import React, {useContext, useEffect} from 'react'
import { Link } from "react-router-dom"
import { AppContext } from '../ContextProvider'
import '../css/Cookie.css'
import $ from 'jquery'
import { useCookies } from "react-cookie"

const Cookies = () => {
    const [cookies, setCookie] = useCookies(['functional', 'performance', 'social'])

    const context = useContext(AppContext)

    useEffect(() => {
        if (!cookies.functional) {
            setTimeout(() => {
                $("#cookies-consent-general").addClass("cookies-consent--active")
            }, 2000)
        }
    }, [])

    const secondPanel = () => {
        $("#cookies-consent-general").removeClass("cookies-consent--active")
        $("#cookies-consent-settings").addClass("cookies-consent--active")
    }

    const acceptAllCookies = () => {
        $("#cookies-consent-general").removeClass("cookies-consent--active")
        handleCookies(true, true)
    }

    const acceptCookies = () => {
        $("#cookies-consent-settings").removeClass("cookies-consent--active")
        handleCookies($('#cookies-consent-settings-2')[0].checked, $('#cookies-consent-settings-3')[0].checked)
    }

    const handleCookies = (performance, social) => {
        const options = {
            path: "/",
            maxAge: 31536000
        }
        setCookie('functional', true, options)
        setCookie('performance', performance, options)
        setCookie('social', social, options)
    }

    const toggleMenu = () => {
        context.setToggle('action')
    }

    return (
        <>
            <div className="cookies-consent" id="cookies-consent-general">
                <div className="cookies-consent__body">
                    <div className="cookies-consent__col-100 cookies-consent__title">
                        NASTAVENÍ SOUBORŮ COOKIE
                </div>

                    <div className="cookies-consent__col-80 cookies-consent__col-split cookies-consent__text">
                        <p>
                            Jménem společnosti EKO-KOM, a.s. vás chceme požádat o souhlas s používáním souborů
                            cookies pro účely výkonu, sociálních médií a reklamy. Sociální média a reklamní
                            soubory cookie třetích stran používáme k tomu, abychom vám mohli nabízet funkce
                            sociálních médií a přizpůsobenou reklamu. Další informace nebo doplnění nastavení
                            získáte kliknutím na tlačítko "Více informací" nebo otevřením nabídky "Nastavení cookies"
                            v dolní části webové stránky. Podrobnější informace o souborech cookie a zpracování vašich
                            osobních údajů najdete v našich <Link to="/ochrana-osobnich-udaju" onClick={toggleMenu}>Zásadách ochrany osobních údajů</Link>
                            &nbsp;a používání souborů cookie. Souhlasíte s používáním souborů cookie a zpracováním souvisejících osobních údajů?
                    </p>
                    </div>

                    <div className="cookies-consent__col-20 cookies-consent__button-group center">
                        <button className="buttonCookiesFull" onClick={acceptAllCookies}>Ano, souhlasím</button>
                        <button className="buttonCookies" onClick={secondPanel}>Více informací</button>
                    </div>

                    <div className="cookies-consent__col-100 cookies-consent__bottom-line">
                        <p>
                            Nastavení si můžete kdykoliv změnit v položce nastavení vašeho prohlížeče.
                    </p>
                    </div>
                </div>
            </div>

            <div className="cookies-consent" id="cookies-consent-settings">
                <div className="cookies-consent__body">
                    <div className="cookies-consent__col-100 cookies-consent__title">
                        NASTAVENÍ SOUBORŮ COOKIE
            </div>

                    <div className="cookies-consent__col-25 cookies-consent__config">
                        <label htmlFor="cookies-consent-settings-1">
                            <input type="checkbox" name="" value="1" id="cookies-consent-settings-1" checked="checked" disabled="disabled" /> Funkční
                </label>
                        <p>
                            Tyto soubory cookie jsou nutné pro základní funkce stránky, a jsou proto vždy povolené. Mezi ně patří soubory cookie, které stránce umožňují si vás zapama- tovat při procházení stránky v rámci jedné relace nebo, pokud o to požádáte, mezi relacemi. Pomáhají se zabezpečením.
                </p>
                    </div>

                    <div className="cookies-consent__col-25 cookies-consent__config">
                        <label htmlFor="cookies-consent-settings-2">
                            <input type="checkbox" name="" value="1" id="cookies-consent-settings-2" /> Výkon
                </label>
                        <p>
                            Soubory cookie nám pomáhají vylepšovat funkce stránek sledováním využití této webové stránky. V některých případech soubory cookie zrychlují zpracování vašeho požadavku a umožňují nám zapamatovat si vaše vybrané předvolby na stránce. Pokud soubory cookie zakážete, může se tím zhoršit přesnost našich doporučení a zpomalit funkčnost stránek.
                </p>
                    </div>

                    <div className="cookies-consent__col-50 cookies-consent__col-split cookies-consent__config ">
                        <label htmlFor="cookies-consent-settings-3">
                            <input type="checkbox" name="" value="1" id="cookies-consent-settings-3" /> Sociální média a reklamy
                </label>
                        <p>
                            Díky souborům cookie sociálních médií se můžete připojit ke svým sociálním sítím
                            a jejich prostřednictvím sdílet obsah z naší stránky.
                            Reklamní soubory cookie (třetích stran) shromažďují informace
                            pro lepší přizpůsobení reklamy vašim zájmům, a to na našich stránkách i mimo ně.
                            V některých případech tyto soubory cookie zpracovávají vaše osobní údaje.
                            Pokud chcete získat více informací o zpracování osobních údajů,
                            přečtěte si naše <Link to="/ochrana-osobnich-udaju" onClick={toggleMenu}>Zásady ochrany osobních údajů</Link>
                            &nbsp;a používání souborů cookie. Pokud zakážete soubory cookie,
                            mohou se zobrazovat reklamy, které méně souvisejí s vašimi zájmy,
                            nebo nebudete moci účinně používat odkazy na Facebook či Instagram
                            anebo nebudete moci sdílet obsah na sociálních médiích.
                </p>
                    </div>

                    <div className="cookies-consent__col-80 cookies-consent__bottom-line">
                        <p>
                            Nastavení si můžete kdykoliv změnit v položce nastavení vašeho prohlížeče.
                </p>
                    </div>
                    <div className="cookies-consent__col-20 cookies-consent__bottom-line center">
                        <button className="buttonCookiesFull" onClick={acceptCookies}>Hotovo</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Cookies
