import React from 'react'
import { useHistory } from 'react-router-dom'

export default ({ siteData }) => {
    const history = useHistory()

    return (
        <div id="mainMenu" className="panelWrapper">
            {siteData.map(({ id, link, name, desc }) =>
                <div key={id}
                    className="menuItem"
                    onClick={() => history.push(`${link}`)}
                >
                    <p className="title">{name}</p>
                    <p className="menuDesc">{desc}</p>
                </div>
            )}
        </div>
    )
}
