import React, {useState, useContext} from 'react'
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import {makeStyles} from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Logo from '../assets/logo.svg'
import axios from "axios";
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';
import {CircularProgress} from "@material-ui/core";
import { useCookies } from "react-cookie"
import {useLocation} from "react-router-dom"

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'© '}
            <Link color="inherit" href="https://material-ui.com/">
                kamtridit.cz
            </Link>{' '}
            {new Date().getFullYear()}
        </Typography>
    )
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: 'black',
        paddingTop: 5,
        paddingBottom: 0,
        paddingRight: 30,
        paddingLeft: 30,
        width: '100%',
        marginBottom: 30,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        height: 50,
    },
    alert: {
        width: '100%',
        marginTop: theme.spacing(2),
    },
    loader: {
        marginTop: 20,
    },
    link: {
        cursor: 'pointer',
    }
}))

export default function Login() {
    const classes = useStyles()

    const [user, setUser] = useState({email: '', password: ''})
    const [error, setError] = useState({email: false, password: false})
    const [openAlert, setOpenAlert] = useState(false)
    const [errorMessage, setErrorMessage] = useState('Chyba. Zkuste prosim pozdeji.')
    const [loading, setLoading] = useState(false)
    const [cookies, setCookie] = useCookies(['user'])
    const search = useLocation().search
    const m = new URLSearchParams(search).get('m')

    const handleSubmit = (e) => {
        e.preventDefault()
        if (!handleValidity()) {
            return
        }
        setLoading(true)

        axios.post(`${process.env.REACT_APP_API}/api/auth/loginAdmin`,
            {email: user.email, password: user.password},
            // { withCredentials: true }
        )
            .then(response => {
                //setCookie('user', response.data.user.email, {path: "/",})
                localStorage.setItem('user', response.data.user.email)
                localStorage.setItem('token', response.data.token)
                //setLoading(false)
                window.location.href = "/admin"
            })
            .catch(error => {
                setLoading(false)
                if (error.response) {
                    setErrorMessage(error.response.data.message)
                }
                setOpenAlert(true)
            })
    }

    const handleError = (field) => {
        if (field === 'email') {
            setError({...error, email: !emailIsValid(user.email)})
        } else if (field === 'password') {
            setError({...error, password: !(user.password.length >= 8)})
        }
    }

    const handleValidity = () => {
        return (emailIsValid(user.email) && user.password.length >= 8)
    }

    const emailIsValid = (email) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
    }

    const valid = handleValidity()

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <div className={classes.paper}>
                <div className={classes.avatar}>
                    <img src={Logo}/>
                </div>
                <Typography component="h1" variant="h5">
                    PŘIHLÁŠENÍ
                </Typography>
                {loading === true &&
                <CircularProgress className={classes.loader}/>
                }
                {m != null && loading === false &&
                <Alert severity="info">
                    {m}
                </Alert>
                }
                {loading === false &&
                <>
                    <Collapse in={openAlert} className={classes.alert}>
                        <Alert severity="error"
                               action={
                                   <IconButton
                                       aria-label="close"
                                       color="inherit"
                                       size="small"
                                       onClick={() => {
                                           setOpenAlert(false)
                                       }}
                                   >
                                       <CloseIcon fontSize="inherit"/>
                                   </IconButton>
                               }
                        >
                            {errorMessage}
                        </Alert>
                    </Collapse>
                    <form className={classes.form} onSubmit={handleSubmit}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="E-mail"
                            name="email"
                            value={user.email}
                            onChange={e => {
                                setUser({...user, email: e.target.value})
                            }}
                            onBlur={(e) => {
                                handleError(e.target.name)
                            }}
                            error={error.email}
                            helperText={error.email ? 'Nevalidní email' : ' '}
                            autoFocus
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            placeholder={'heslo min. 8 znaků'}
                            required
                            fullWidth
                            name="password"
                            label="Heslo"
                            type="password"
                            id="password"
                            value={user.password}
                            onChange={e => {
                                setUser({...user, password: e.target.value})
                            }}
                            onBlur={(e) => {
                                handleError(e.target.name)
                            }}
                            error={error.password}
                            helperText={error.password ? 'Nevalidní heslo' : ' '}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            disabled={!valid}
                            color="primary"
                            className={classes.submit}
                        >
                            PŘIHLÁsit SE
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link to="/uzivatel/zapomenute-heslo" className={classes.link} onClick={() => window.location.href = "/uzivatel/zapomenute-heslo"} variant="body2">
                                    Zapomenuté heslo?
                                </Link>
                            </Grid>
                        </Grid>
                    </form>
                    <Box mt={8}>
                        <Copyright/>
                    </Box>
                </>
                }
            </div>


        </Container>
    )
}