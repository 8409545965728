import React, { Component } from 'react'

export const AppContext = React.createContext()

class ContextProvider extends Component {

    static ActionsEnum = {
        CLICK: 0,
        OVER: 1,
        OUT: 2,
        BACK: 3,
        UNDEFINED: 4
    }

    state = {
        message: "",
        toggle: null,
        place: "",
        placeAction: ContextProvider.ActionsEnum.UNDEFINED,
        lat: 0,
        lng: 0,
        filter: [false, false, false, false, false, false],
        isAdmin: false
    }

    render() {
        return (
            <AppContext.Provider value={
                {
                    state: this.state,
                    setMessage: (value) => this.setState({
                        message: value
                    }),
                    setToggle: (value) => this.setState({
                        toggle: value
                    }),
                    setPlace: (place, action) => this.setState({
                        place: place,
                        placeAction: action
                    }),
                    setCoordinates: (lat, lng) => this.setState({
                        lat: lat,
                        lng: lng
                    }),
                    setFilter: (value) => this.setState({
                        filter: value
                    }),
                    setIsAdmin: (value) => this.setState({
                        isAdmin: value
                    }),
                }}>
                {this.props.children}
            </AppContext.Provider>
        )
    }
}

export default ContextProvider
