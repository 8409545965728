import React, {Component} from 'react'
import AppBar from '@material-ui/core/AppBar'
import CssBaseline from '@material-ui/core/CssBaseline'
import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import Toolbar from '@material-ui/core/Toolbar'
import {compose} from 'recompose'
import {createMuiTheme, withStyles} from '@material-ui/core/styles'
import {Link, Route, Switch, withRouter} from "react-router-dom"
import About from './panels/About'
import Search from './panels/Search'
import Where from './panels/Where'
import Legal from './panels/Legal'
import Involve from './panels/Involve'
import Filter from './panels/Filter'
import PlaceAdmin from './admin/PlaceAdmin'
import PlaceNew from "./admin/PlaceNew";
import Logo from './assets/logo.svg'
import Box from '@material-ui/core/Box'
import {AppContext} from './ContextProvider'

const drawerWidth = 360

const customTheme = createMuiTheme({
    mixins: {
        toolbar: {
            minHeight: 80
        }
    }
})

const styles = theme => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('md')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        [theme.breakpoints.up('md')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    toolbarHide: {
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    toolbar: customTheme.mixins.toolbar,
    toolbarCustom: {
        minHeight: 80,
        // boxShadow: 'none',
        backgroundColor: '#000000',
    },
    drawerPaper: {
        [theme.breakpoints.up('md')]: {
            width: drawerWidth,
            backgroundColor: '#414143',
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            backgroundColor: "transparent",
            // height: '200px',
            // pointerEvents: 'none',
        },
    },
    drawerHeader: {
        position: 'fixed',
        width: 360,
        height: 120,
        backgroundColor: '#141213',
    },
    drawerLogo: {
        paddingLeft: 36,
        paddingTop: 36,
        width: 260,
        // cursor: 'pointer',
    },
    drawerBg: {
        position: 'fixed',
        width: '100%',
        height: 1200,
        backgroundColor: '#414143',
        zIndex: -1,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(0),
        height: '100vh',//`calc(100vh - 200px)`//
        // pointerEvents: 'auto',
    },
    logo: {
        width: 150,
      }
})

class Layout extends Component {

    state = {
        mobileOpen: false
    }

    static contextType = AppContext

    constructor(props){
        super(props)
    }

    componentDidUpdate() {
        const ctx = this.context
        if (ctx.state.toggle != null) {
            ctx.setToggle(null)
            this.handleDrawerToggle()
        }
    }

    handleDrawerToggle = () => {
        this.setState({ mobileOpen: !this.state.mobileOpen })
    }

    parseJwt(token) {
        try {
            return JSON.parse(atob(token.split('.')[1]))
        } catch (e) {
            return null
        }
    }

    assertAlive(decoded) {
        const now = Date.now().valueOf() / 1000
        if (typeof decoded.exp !== 'undefined' && decoded.exp < now) {
            throw new Error(`token expired: ${JSON.stringify(decoded)}`)
        }
    }

    render() {
        if (window.location.pathname.substring(0,6) === '/admin') {
            const token = this.parseJwt(localStorage.getItem('token'))
            try {
                this.assertAlive(token)
            } catch (error) {
                window.location.href = "/uzivatel/prihlaseni?m=Z důvodu nečinnosti proběhlo odhlášení."
            }
        }

        const { location } = this.props
        const { classes, children } = this.props
        const { mobileOpen } = this.state

        const renderDrawerBg = () => {
            if (location.pathname !== '/filtrovani') {
                return (
                    <Hidden mdUp>
                        <div className={classes.drawerBg}/>
                    </Hidden>
                )
            }
        }

        const drawer = (
            <>
                <Hidden smDown>
                    <div className={classes.drawerHeader}><img src={Logo} className={classes.drawerLogo} /></div>
                    {/*<div className={classes.drawerHeader}><Link to="/"><img src={Logo} className={classes.drawerLogo} /></Link></div>*/}
                </Hidden>
                {renderDrawerBg()}
                {children[0]}
                <Switch>
                    <Route path={'/vyhledat-misto'}>
                        <Search id={'VYHLEDAT MÍSTO'} />
                    </Route>
                    <Route path={'/filtrovani'}>
                        <Filter id={'FILTROVÁNÍ'} />
                    </Route>
                    <Route path={'/kam-patri'}>
                        <Where id={'KAM PATŘÍ'} />
                    </Route>
                    <Route path='/zapojte-se'>
                        <Involve id={'ZAPOJTE SE'} />
                    </Route>
                    <Route path='/o-nas'>
                        <About id={'O NÁS'} />
                    </Route>
                    <Route path={'/ochrana-osobnich-udaju'}>
                        <Legal id={'PROVOZNÍ PODMÍNKY'} />
                    </Route>
                    <Route path={`/admin/nove`} component={PlaceNew} />
                    <Route path="/admin/misto/:id" render={(props) => <PlaceAdmin {...props} />}/>
                    {/*<Route path="/misto/:id" render={(props) => <Map {...props} />}/>*/}
                </Switch>

                    {/* <Route path={`/o-nas/misto`} component={About} /> */}
                    {/*<Route path={`/admin/misto`} component={PlaceAdmin} />*/}

            </>
        )

        return <>
            <div className={classes.root}>
                 {/*<CssBaseline />*/}
                <nav className={classes.drawer} aria-label="mailbox folders">
                    <Hidden mdUp>
                        <Drawer
                            variant="temporary"
                            open={mobileOpen}
                            onClose={this.handleDrawerToggle}
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                            style={{ zIndex: 1 }}
                            ModalProps={{
                                keepMounted: true,
                                hideBackdrop: true,
                            }}
                        >
                            {drawer}
                        </Drawer>
                    </Hidden>
                    <Hidden smDown>
                        <Drawer
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                            variant="permanent"
                            open
                        >
                            {drawer}
                        </Drawer>
                    </Hidden>
                </nav>
                <main className={classes.content}>
                    <div className={[classes.toolbarCustom, classes.toolbarHide].join(' ')} />
                    {children[1]}
                </main>
            </div>
            <AppBar position="fixed" className={[classes.appBar, classes.toolbarHide].join(' ')}>
                <Toolbar className={[classes.toolbar, classes.toolbarCustom, classes.toolbarHide].join(' ')}>
                <Box display='flex' flexGrow={1}>
                <Link to="/"><img src={Logo} className={classes.logo} /></Link>
                    
                    </Box>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={this.handleDrawerToggle}
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
        </>
    }
}

export default compose(
    withRouter,
    withStyles(styles)
)(Layout)

