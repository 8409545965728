import React, {useContext} from 'react'
import Logo from '../assets/logoekokom.svg'
import {Link} from 'react-router-dom'
import PanelNavigation from '../PanelNavigation'

export default ({ id }) => {
    const reload = () => {
        window.location.href = "/uzivatel/prihlaseni"
    }

    return (
        <>
            <PanelNavigation id={id} panel={'panelAbout'} />
            <div id="panelAbout" className="panelWrapper">
                <p>Projekt kamtřídit.cz je interaktivní mapou zobrazující barevné kontejnery na tříděný odpad
                    a sběrné dvory na území ČR. Navíc zde najdete také užitečný návod kam třídit jednotlivé druhy domácího odpadu.</p>
                <br />
                <p onClick={() => reload()} style={{cursor:'pointer'}}>Autorizovaný přístup</p>
                <br />
                <h3>KONTAKT</h3>
                <br />
                <a href={void(0)} className="mail" rel="noreferrer">info(zavináč)kamtridit.cz</a>
                <br />
                <ul>
                    <li><a href="https://www.facebook.com/MaToSmyslTridimOdpad" target="_blank" rel="noreferrer">MaToSmyslTridimOdpad</a></li>
                </ul>
                <p>kamtridit.cz {new Date().getFullYear()} ©</p>
                <br />
                <img src={Logo} alt="Logo Ekokom" />
                <br />
                <a href="https://ekokom.cz" target="_blank" rel="noreferrer">ekokom.cz</a>
                <a href="https://jaktridit.cz" target="_blank" rel="noreferrer">jaktridit.cz</a>
                <a href="https://samosebou.cz" target="_blank" rel="noreferrer">samosebou.cz</a>
                <br />
                <Link to="/ochrana-osobnich-udaju">Ochrana osobních údajů</Link>
                <br />
            </div>
        </>
    )
}
