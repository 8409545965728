import React from 'react'
import SearchInput from './SearchInput'
import PanelNavigation from '../PanelNavigation'

export default ({ id }) =>
    <>
        <PanelNavigation id={id} panel={'panelSearch'} />
        <div id="panelSearch" className="panelWrapper">
            <p>vyhledat nejbližší sběrné místo pro zadanou adresu</p>
            <SearchInput />
        </div>
    </>
