import React from 'react'
import Direction from './assets/direction.svg'

export default ({ marker }) => {

    const date = new Date(marker.updatedAt).toLocaleDateString('cs-CZ').replace(/\//g, '.')

    return (
        <div id="infoWindow">
            <h2>DETAIL MÍSTA</h2>
            <div id="infoWindowContainer">
                <WasteContainer cluster={marker.cluster} />
            </div>
            <div id="infoWindowClick" lat={marker.lat} lng={marker.lng}>
                <img src={Direction} />
                <h3>{marker.position.name + ', ' + marker.position.city}</h3>
            </div>
            <hr />
            <p>Poslední aktualizace:<br />{date}</p>
        </div>
    )
}

const WasteContainer = ({ cluster }) => {

    let containers = []
    if (cluster.courtyard) {
        containers.push(<li key="iwPlace" id="iwPlace">Sběrný dvůr</li>)
    } else {
        if (cluster.glass) {
            containers.push(<li key="iwGlass" id="iwGlass">Sklo</li>)
        }
        if (cluster.plastic) {
            containers.push(<li key="iwPlastic" id="iwPlastic">Plasty</li>)
        }
        if (cluster.paper) {
            containers.push(<li key="iwPaper" id="iwPaper">Papír</li>)
        }
        if (cluster.drinkCarton) {
            containers.push(<li key="iwDrinkCarton" id="iwDrinkCarton">Nápojové kartony</li>)
        }
        if (cluster.metal) {
            containers.push(<li key="iwMetal" id="iwMetal">Kovy</li>)
        }
    }

    return (
        <ul>
            {containers.map((el) => el)}
        </ul>
    )
}
