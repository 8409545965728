import React, { useState, useContext } from 'react'
import { AppContext } from '../ContextProvider'
import AsyncSelect from 'react-select/async'
import { components } from 'react-select'
import debounce from 'debounce-promise'

const SearchInput = () => {

    const [inputValue, setValue] = useState('')
    const [selectedValue, setSelectedValue] = useState(null)

    const context = useContext(AppContext)

    const handleInputChange = value => {
        setValue(value)
    }

    const handleChange = value => {
        if (value != null) {
            const placeId = value['place_id']
            if (placeId !== undefined) {
                setSelectedValue(placeId)
                context.setMessage(placeId)
                context.setToggle('action')
            }
        }
    }

    const loadOptions = (inputValue) => {
        if (inputValue.length < 3) {
            return
        }
        return fetch(`${process.env.REACT_APP_API}/maps/search?q=${inputValue}`).then(res => res.json())
    }

    const Placeholder = props => {
        return <components.Placeholder {...props} />
    }

    return (
        <div>
            <AsyncSelect
                cacheOptions
                defaultOptions
                //onBlurResetsInput={true}
                openMenuOnClick={false}
                backspaceRemoves={false}
                isClearable
                components={{ Placeholder }}
                placeholder={'Zadejte obec, čtvrť, ulici...'}
                //value={{ value: selectedValue, label: 'Vyhledat' }}
                getOptionLabel={e => e.description}
                getOptionValue={e => e.description}
                loadOptions={debounce(loadOptions.bind(this), 500)}
                //onInputChange={handleInputChange}
                //noOptionsMessage={''}
                onChange={handleChange}
                components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null
                }}
            // styles={{
            //     dropdownIndicator: (provided, state) => ({
            //         ...provided,
            //         transform: state.selectProps.menuIsOpen && 'rotate(180deg)'
            //     })
            // }}
            />
        </div>
    )
}

export default SearchInput
