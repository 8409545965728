import React, {useContext, useEffect} from 'react'
import PanelNavigation from '../PanelNavigation'
import {useLocation} from "react-router-dom"
import ContextProvider, { AppContext } from '../ContextProvider'
import axios from "axios";

export default ({ id }) => {

    const context = useContext(AppContext)
    const search = useLocation().search
    const mobileStatus = new URLSearchParams(search).get('mobile')

    const toggleMenu = () => {
        context.setToggle('action')
    }

    useEffect(() => {
        if(mobileStatus === "true") {
            toggleMenu()
        }
    }, [])

    return (
        <>
            <PanelNavigation id={id} panel={'panelLegal'} />
            <div id="panelLegal" className="panelWrapper">
                <div id="legal">
                    <h3>ZÁSADY OCHRANY OSOBNÍCH ÚDAJŮ</h3>

                    <p>Tyto zásady ochrany osobních údajů stanoví, jakým způsobem používáme a chráníme vaše
                    osobní údaje,
                    které jsou nám předávány používáním této webové stránky www.kamtridit.cz (dále jen
        "Webová stránka").</p>

                    <p>Správcem osobních údajů je společnost EKO-KOM, a.s., se sídlem Praha 4, Na Pankráci
                    1685/17, IČ 251 34 701,
                    spisová značka B 4763 vedená u Městského soudu v Praze (dále jako "EKO-KOM").
                    EKO-KOM se zavazuje zajistit bezpečí vašich osobních údajů.
                    Veškeré vaše osobní údaje mohou být použity pouze v souladu s těmito zásadami ochrany
                    osobních údajů.
                    EKO-KOM je oprávněna tyto zásady ochrany osobních údajů čas od času změnit jejich
        aktualizací na Webové stránce.</p>

                    <h3>Co shromažĎujeme</h3>
                    <p>Správce shromažďuje a zpracovává následující osobní údaje:</p>
                    <ul>
                        <li>v případě, rozhodnete-li se účastnit soutěže prostřednictvím Webové stránky jako
                        soutěžící,
                        zpracovává EKO-KOM vaše osobní údaje v následujícím rozsahu: jméno a příjmení,
                        e-mail,
                        poštovní adresa pro doručení výhry a příp. telefonní číslo, údaje o výhře,
                        případné fotografie a audiovizuální záznamy pořízené EKO-KOM v souvislosti s výhrou
                        v soutěži
            nebo Vámi předané EKO-KOM v souvislosti s účastí v soutěži;</li>
                        <li>záznamy o korespondenci (v případě kontaktu ze strany subjektu údajů);</li>
                        <li>informace poskytnuté v jakémkoli poli "volný text" na Webové stránce (tj. prázdná
            místa, kam můžete libovolně vkládat komentáře).</li>
                    </ul>
                    <h3>K jakému účelu osobní údaje zpracováváme</h3>
                    <ul>
                        <li> vaše osobní data využijeme pouze k účelům, pro které jste nám je poskytli (zejména pro
                        umožnění vaší účasti v soutěžích a čerpání výher, umožnění vkládání příspěvků do
            soutěží, hlasování v soutěžích);</li>
                        <li> v případě, máme-li k tomu od vás odpovídající souhlas, využíváme vaše kontaktní údaje k
                        (e-mail/telefonní číslo) k zasílání marketingových sdělení o produktech a službách, tak
            jak je blíže popsáno v sekci Přímý marketing (těchto zásad);</li>
                        <li> pro ochranu našich oprávněných zájmům (zejména pro zamezení podvodným jednáním, ve
                        snaze předcházet a zabránit porušení našich podmínek, pro účely vymáhání případných
            pohledávek a k zajištění ochrany našich zákonných práv);</li>
                        <li> vaše kontaktní údaje můžeme dále využívat pro zajištění naší vzájemné komunikace a pro
            účely umožnění výkonu vašich práv v souvislosti s ochranou osobních údajů;</li>
                        <li> pro další specifické účely, pro které jste nám udělili souhlas.</li>
                        <li> Doba uchování osobních údajů je vázána na existenci souhlasu nebo trvání obchodní
                        činnosti správce (v některých případech na dobu trvání soutěže nebo marketinkové akce).
        </li>
                    </ul>
                    <h3>Technická data k návštěvě Webové stránky</h3>
                    <p>Webové stránky využívají službu Google Analytics, poskytovanou společností Google,
                    Inc. (dále jen "Google"). Služba Google Analytics používá souborů "cookies", které
                    jsou textovými soubory ukládanými do vašeho počítače, umožňujícími analýzu způsobu
                    užívání této stránky jejími uživateli. Informace vygenerované souborem cookie o
                    užívání stránky (včetně vaší IP adresy) budou společností Google přeneseny a uloženy
        na serverech ve Spojených státech.</p>
                    <p>Google bude užívat tyto informace pro účely vyhodnocování užívání stránky a vytváření
                    zpráv o její aktivitě, určených pro její provozovatele, a pro poskytování dalších
                    služeb týkajících se činností na stránce a užívání internetu vůbec. Google může také
                    poskytnout tyto informace třetím osobám, bude-li to požadováno zákonem nebo budou-li
                    takovéto třetí osoby zpracovávat tyto informace pro Google. Google nebude spojovat
                    vaši IP adresu s jakýmikoli jinými daty, která má k dispozici. Můžete odmítnout
                    používání souborů cookies volbou v příslušném nastavení ve vašem prohlížeči, avšak
                    uvědomte si, že jestliže tak učiníte, nebudete schopni plně využívat veškeré funkce
                    této stránky. Používáním této stránky souhlasíte se zpracováváním údajů o vás
        společností Google, a to způsobem a k účelům shora uvedeným.</p>
                    <ul>
                        <li> Detailní informace o Google Analytics a ochraně osobních údajů naleznete na
            http://www.google.com/intl/cs/privacy/privacy-policy.html.</li>
                        <li> Pokud chcete zabránit sledování, můžete instalovat doplněk do vašeho webového
            prohlížeče (http://tools.google.com/dlpage/gaoptout).</li>
                    </ul>
                    <p>Webová stránka obsahuje záložky sociálních sítí (např. Facebook a Instagram).
                    "Záložky sociálních sítí" jsou internetové záložky, pomocí nichž mohou uživatelé
                    těchto sítí shromažďovat odkazy a novinky. Tyto záložky jsou na našem webu umístěny
                    pouze jako odkazy na odpovídající sociální sítě. Na stránky daného poskytovatele
                    služeb budete přesměrováni, pouze pokud kliknete na grafický prvek sítě - teprve
                    tehdy získá poskytovatel služby uživatelské informace. Informace týkající se
                    zacházení s vašimi osobními daty při používání stránek se záložkami sociálních sítí
                    můžete nalézt v následujícím prohlášení o nakládání s osobními daty. Použití těchto
                    služeb mimo rozsah naší nabídky se řídí výhradně prohlášením o nakládání s osobními
        daty daného poskytovatele služby.</p>
                    <p>Webová stránka může obsahovat odkazy na jiné webové stránky. Pokud však takový odkaz
                    použijete a naši Webovou stránku opustíte, měli byste si uvědomit, že nad těmito
                    jinými webovými stránkami nemáme žádnou kontrolu. Nemůžeme proto zodpovídat za
                    ochranu a soukromí jakýchkoli informací, které poskytnete při navštívení takových
        stránek, a na takové stránky se nevztahují tyto zásady ochrany osobních údajů.</p>

                    <h3>Přímý marketing</h3>
                    <p>Pokud si přejete odebírat některý z našich zpravodajů, budeme od vás potřebovat vaši
                    platnou e-mailovou adresu a informace, které nám umožní ověřit si, že jste majitelem
                    uvedené e-mailové adresy a souhlasíte s odběrem zpravodaje. Tato data nebudou
                    poskytnuta třetím stranám. Při registraci k odběru zpravodaje uložíme kromě vaší
                    e-mailové adresy i vaši IP adresu a datum a čas registrace. Uložení těchto dat
                    slouží pouze jako důkaz v případě, že třetí strana zneužije vaši e-mailovou adresu a
                    registruje se k odběru zpravodaje bez vašeho vědomí. Svůj předchozí souhlas s
                    uložením těchto dat a vaší e-mailové adresy a jejich použitím k zasílání zpravodaje
                    můžete kdykoli odvolat. Odvolání může být provedeno pomocí odkazu ve zpravodaji nebo
        zasláním odvolání na kontakty uvedené ve zpravodaji nebo níže v tomto dokumentu.</p>

                    <h3>Kde ukládáme vaše osobní údaje</h3>
                    <p>Vaše osobní údaje jsou uchovávány na našich zabezpečených serverech v našich IT
                    systémech v České republice. Osobní údaje jsou shromažďovány, zpracovávány a
                    uchovávány výhradně na území České republiky a nedochází ani nebude docházet k
        jejich předávání do jiných států.</p>

                    <h3>Bezpečnost</h3>
                    <p>Zavazujeme se zajistit bezpečnost vašich osobních údajů. Abychom zabránili
                    neoprávněnému přístupu k nim nebo jejich neoprávněnému zveřejnění, zavedli jsme
                    vhodné fyzické, elektronické a manažerské postupy pro ochranu a zabezpečení
                    informací, které na Webové stránce shromažďujeme. Berete na vědomí, že vaše osobní
                    údaje, které nám poskytnete, budou spravovány systematicky automatickým způsobem za
        použití automatických a veškerých dalších nástrojů informačního systému.</p>

                    <h3>Poskytování vašich osobních údajů</h3>
                    <p>Osobní údaje budou pro EKO-KOM zpracovávány zpracovatelem, společností HAVAS
                    WORLDWIDE PRAGUE, a.s., se sídlem Praha 7, Letenské sady č.p. 1500 (EXPO 58), PSČ
                    17000, IČ 630 79 054, spisová značka B 3138 vedená u Městského soudu v Praze, tel.
                    +420220397600, e-mail:recepce@havas.com.
                    V případě vaší výhry v soutěži mohou být vaše kontaktní údaje předány poskytovateli
                    kurýrní či přepravní služby za účelem doručení výhry v soutěži a vaše jméno a
                    příjmení budou zveřejněny na Webové stránce v souvislosti se zveřejněním informací o
                    výhrách. Při předávání ceny mohou být pořízeny fotografie či audiovizuální záznamy,
                    které budou zveřejněny ve vybraných médiích, případně šířeny jiným obvyklým
                    způsobem.
                    Dále mohou být při fotosoutěžích zveřejněny fotografie, které do soutěže poskytnete.
    </p>
                    <p>Ostatní osobní údaje nebudou dalším osobám zpřístupněny s výjimkou případů, kdy
                    zpřístupnění představuje povinnost stanovenou zákonem (např. orgány činné v trestním
                    řízení, soudy) nebo jsou osobní údaje zpřístupněny z důvodu ochrany práv správce
        osobám, které jsou ze zákona vázány mlčenlivostí.</p>

                    <h3>Práva subjektů údajů</h3>
                    <p>Při shromažďování, zpracovávání a uchovávání vašich osobních údajů dbáme v plném
                    rozsahu ochrany vašich práv tak, jak vyplývají z příslušných ustanovení právních
                    předpisů. V souvislosti se zpracováním osobních údajů vám náleží níže specifikovaná
                    práva, která můžete dle vašeho uvážení využít.Máte právo přístupu k osobním údajům,
                    právo na opravu osobních údajů, právo požádat správce (EKO-KOM, a.s.) nebo
                    zpracovatele (HAVAS WORLDWIDE PRAGUE, a.s.) o vysvětlení a odstranění závadného
                    stavu (např. blokování/omezení zpracování, provedení opravy, doplnění nebo
                    likvidace/výmaz osobních údajů). Máte též právo na přenositelnost automatizovaně
                    zpracovávaných osobních údajů (tj. získat osobní údaje od správce a předat je jinému
                    správci).Dále máte právo po správci nebo zpracovateli požadovat náhradu majetkové i
                    nemajetkové újmy způsobené zpracováním osobních údajů a právo podat stížnost u Úřadu
        pro ochranu osobních údajů Pplk. Sochora 27, PSČ 170 00, Praha 7, www.uoou.cz.</p>

                    <p>PROTI ZPRACOVÁNÍ OSOBNÍCH ÚDAJŮ PRO ÚČELY PŘÍMÉHO MARKETINGU MŮŽETE KDYKOLI VZNÉST
                    NÁMITKU A VAŠE OSOBNÍ ÚDAJE BUDOU OKAMŽITĚ VYMAZÁNY!PROTI ZPRACOVÁNÍ OSOBNÍCH ÚDAJŮ
                    Z DŮVODU OPRÁVNĚNÝCH ZÁJMŮ SPRÁVCE MŮŽETE KDYKOLI VZNÉST NÁMITKU A OSOBNÍ ÚDAJE
                    BUDOU VYMAZÁNY, pokud správce neprokáže závažné oprávněné důvody pro zpracování,
        které převažují nad VAŠIMI zájmy nebo právy.</p>

                    <p>V souvislosti s výkonem svých práv a v případě jakýchkoli otázek či informací ohledně
                    nakládání s osobními údaji, prosím, kontaktujte nás na tel. +420220397600 nebo
                    písemně na recepce@havas.com.
        Tyto zásady ochrany osobních údajů jsou platné od 29. 7. 2020.</p>
                    <br />
                </div>
            </div><div id="panelLegal">
                <div id="legal">
                    <h3>ZÁSADY OCHRANY OSOBNÍCH ÚDAJŮ</h3>

                    <p>Tyto zásady ochrany osobních údajů stanoví, jakým způsobem používáme a chráníme vaše
                    osobní údaje,
                    které jsou nám předávány používáním této webové stránky www.kamtridit.cz (dále jen
        "Webová stránka").</p>

                    <p>Správcem osobních údajů je společnost EKO-KOM, a.s., se sídlem Praha 4, Na Pankráci
                    1685/17, IČ 251 34 701,
                    spisová značka B 4763 vedená u Městského soudu v Praze (dále jako "EKO-KOM").
                    EKO-KOM se zavazuje zajistit bezpečí vašich osobních údajů.
                    Veškeré vaše osobní údaje mohou být použity pouze v souladu s těmito zásadami ochrany
                    osobních údajů.
                    EKO-KOM je oprávněna tyto zásady ochrany osobních údajů čas od času změnit jejich
        aktualizací na Webové stránce.</p>

                    <h3>Co shromažĎujeme</h3>
                    <p>Správce shromažďuje a zpracovává následující osobní údaje:</p>
                    <ul>
                        <li>v případě, rozhodnete-li se účastnit soutěže prostřednictvím Webové stránky jako
                        soutěžící,
                        zpracovává EKO-KOM vaše osobní údaje v následujícím rozsahu: jméno a příjmení,
                        e-mail,
                        poštovní adresa pro doručení výhry a příp. telefonní číslo, údaje o výhře,
                        případné fotografie a audiovizuální záznamy pořízené EKO-KOM v souvislosti s výhrou
                        v soutěži
            nebo Vámi předané EKO-KOM v souvislosti s účastí v soutěži;</li>
                        <li>záznamy o korespondenci (v případě kontaktu ze strany subjektu údajů);</li>
                        <li>informace poskytnuté v jakémkoli poli "volný text" na Webové stránce (tj. prázdná
            místa, kam můžete libovolně vkládat komentáře).</li>
                    </ul>
                    <h3>K jakému účelu osobní údaje zpracováváme</h3>
                    <ul>
                        <li> vaše osobní data využijeme pouze k účelům, pro které jste nám je poskytli (zejména pro
                        umožnění vaší účasti v soutěžích a čerpání výher, umožnění vkládání příspěvků do
            soutěží, hlasování v soutěžích);</li>
                        <li> v případě, máme-li k tomu od vás odpovídající souhlas, využíváme vaše kontaktní údaje k
                        (e-mail/telefonní číslo) k zasílání marketingových sdělení o produktech a službách, tak
            jak je blíže popsáno v sekci Přímý marketing (těchto zásad);</li>
                        <li> pro ochranu našich oprávněných zájmům (zejména pro zamezení podvodným jednáním, ve
                        snaze předcházet a zabránit porušení našich podmínek, pro účely vymáhání případných
            pohledávek a k zajištění ochrany našich zákonných práv);</li>
                        <li> vaše kontaktní údaje můžeme dále využívat pro zajištění naší vzájemné komunikace a pro
            účely umožnění výkonu vašich práv v souvislosti s ochranou osobních údajů;</li>
                        <li> pro další specifické účely, pro které jste nám udělili souhlas.</li>
                        <li> Doba uchování osobních údajů je vázána na existenci souhlasu nebo trvání obchodní
                        činnosti správce (v některých případech na dobu trvání soutěže nebo marketinkové akce).
        </li>
                    </ul>
                    <h3>Technická data k návštěvě Webové stránky</h3>
                    <p>Webové stránky využívají službu Google Analytics, poskytovanou společností Google,
                    Inc. (dále jen "Google"). Služba Google Analytics používá souborů "cookies", které
                    jsou textovými soubory ukládanými do vašeho počítače, umožňujícími analýzu způsobu
                    užívání této stránky jejími uživateli. Informace vygenerované souborem cookie o
                    užívání stránky (včetně vaší IP adresy) budou společností Google přeneseny a uloženy
        na serverech ve Spojených státech.</p>
                    <p>Google bude užívat tyto informace pro účely vyhodnocování užívání stránky a vytváření
                    zpráv o její aktivitě, určených pro její provozovatele, a pro poskytování dalších
                    služeb týkajících se činností na stránce a užívání internetu vůbec. Google může také
                    poskytnout tyto informace třetím osobám, bude-li to požadováno zákonem nebo budou-li
                    takovéto třetí osoby zpracovávat tyto informace pro Google. Google nebude spojovat
                    vaši IP adresu s jakýmikoli jinými daty, která má k dispozici. Můžete odmítnout
                    používání souborů cookies volbou v příslušném nastavení ve vašem prohlížeči, avšak
                    uvědomte si, že jestliže tak učiníte, nebudete schopni plně využívat veškeré funkce
                    této stránky. Používáním této stránky souhlasíte se zpracováváním údajů o vás
        společností Google, a to způsobem a k účelům shora uvedeným.</p>
                    <ul>
                        <li> Detailní informace o Google Analytics a ochraně osobních údajů naleznete na
            http://www.google.com/intl/cs/privacy/privacy-policy.html.</li>
                        <li> Pokud chcete zabránit sledování, můžete instalovat doplněk do vašeho webového
            prohlížeče (http://tools.google.com/dlpage/gaoptout).</li>
                    </ul>
                    <p>Webová stránka obsahuje záložky sociálních sítí (např. Facebook a Instagram).
                    "Záložky sociálních sítí" jsou internetové záložky, pomocí nichž mohou uživatelé
                    těchto sítí shromažďovat odkazy a novinky. Tyto záložky jsou na našem webu umístěny
                    pouze jako odkazy na odpovídající sociální sítě. Na stránky daného poskytovatele
                    služeb budete přesměrováni, pouze pokud kliknete na grafický prvek sítě - teprve
                    tehdy získá poskytovatel služby uživatelské informace. Informace týkající se
                    zacházení s vašimi osobními daty při používání stránek se záložkami sociálních sítí
                    můžete nalézt v následujícím prohlášení o nakládání s osobními daty. Použití těchto
                    služeb mimo rozsah naší nabídky se řídí výhradně prohlášením o nakládání s osobními
        daty daného poskytovatele služby.</p>
                    <p>Webová stránka může obsahovat odkazy na jiné webové stránky. Pokud však takový odkaz
                    použijete a naši Webovou stránku opustíte, měli byste si uvědomit, že nad těmito
                    jinými webovými stránkami nemáme žádnou kontrolu. Nemůžeme proto zodpovídat za
                    ochranu a soukromí jakýchkoli informací, které poskytnete při navštívení takových
        stránek, a na takové stránky se nevztahují tyto zásady ochrany osobních údajů.</p>

                    <h3>Přímý marketing</h3>
                    <p>Pokud si přejete odebírat některý z našich zpravodajů, budeme od vás potřebovat vaši
                    platnou e-mailovou adresu a informace, které nám umožní ověřit si, že jste majitelem
                    uvedené e-mailové adresy a souhlasíte s odběrem zpravodaje. Tato data nebudou
                    poskytnuta třetím stranám. Při registraci k odběru zpravodaje uložíme kromě vaší
                    e-mailové adresy i vaši IP adresu a datum a čas registrace. Uložení těchto dat
                    slouží pouze jako důkaz v případě, že třetí strana zneužije vaši e-mailovou adresu a
                    registruje se k odběru zpravodaje bez vašeho vědomí. Svůj předchozí souhlas s
                    uložením těchto dat a vaší e-mailové adresy a jejich použitím k zasílání zpravodaje
                    můžete kdykoli odvolat. Odvolání může být provedeno pomocí odkazu ve zpravodaji nebo
        zasláním odvolání na kontakty uvedené ve zpravodaji nebo níže v tomto dokumentu.</p>

                    <h3>Kde ukládáme vaše osobní údaje</h3>
                    <p>Vaše osobní údaje jsou uchovávány na našich zabezpečených serverech v našich IT
                    systémech v České republice. Osobní údaje jsou shromažďovány, zpracovávány a
                    uchovávány výhradně na území České republiky a nedochází ani nebude docházet k
        jejich předávání do jiných států.</p>

                    <h3>Bezpečnost</h3>
                    <p>Zavazujeme se zajistit bezpečnost vašich osobních údajů. Abychom zabránili
                    neoprávněnému přístupu k nim nebo jejich neoprávněnému zveřejnění, zavedli jsme
                    vhodné fyzické, elektronické a manažerské postupy pro ochranu a zabezpečení
                    informací, které na Webové stránce shromažďujeme. Berete na vědomí, že vaše osobní
                    údaje, které nám poskytnete, budou spravovány systematicky automatickým způsobem za
        použití automatických a veškerých dalších nástrojů informačního systému.</p>

                    <h3>Poskytování vašich osobních údajů</h3>
                    <p>Osobní údaje budou pro EKO-KOM zpracovávány zpracovatelem, společností HAVAS
                    WORLDWIDE PRAGUE, a.s., se sídlem Praha 7, Letenské sady č.p. 1500 (EXPO 58), PSČ
                    17000, IČ 630 79 054, spisová značka B 3138 vedená u Městského soudu v Praze, tel.
                    +420220397600, e-mail:recepce@havas.com.
                    V případě vaší výhry v soutěži mohou být vaše kontaktní údaje předány poskytovateli
                    kurýrní či přepravní služby za účelem doručení výhry v soutěži a vaše jméno a
                    příjmení budou zveřejněny na Webové stránce v souvislosti se zveřejněním informací o
                    výhrách. Při předávání ceny mohou být pořízeny fotografie či audiovizuální záznamy,
                    které budou zveřejněny ve vybraných médiích, případně šířeny jiným obvyklým
                    způsobem.
                    Dále mohou být při fotosoutěžích zveřejněny fotografie, které do soutěže poskytnete.
    </p>
                    <p>Ostatní osobní údaje nebudou dalším osobám zpřístupněny s výjimkou případů, kdy
                    zpřístupnění představuje povinnost stanovenou zákonem (např. orgány činné v trestním
                    řízení, soudy) nebo jsou osobní údaje zpřístupněny z důvodu ochrany práv správce
        osobám, které jsou ze zákona vázány mlčenlivostí.</p>

                    <h3>Práva subjektů údajů</h3>
                    <p>Při shromažďování, zpracovávání a uchovávání vašich osobních údajů dbáme v plném
                    rozsahu ochrany vašich práv tak, jak vyplývají z příslušných ustanovení právních
                    předpisů. V souvislosti se zpracováním osobních údajů vám náleží níže specifikovaná
                    práva, která můžete dle vašeho uvážení využít.Máte právo přístupu k osobním údajům,
                    právo na opravu osobních údajů, právo požádat správce (EKO-KOM, a.s.) nebo
                    zpracovatele (HAVAS WORLDWIDE PRAGUE, a.s.) o vysvětlení a odstranění závadného
                    stavu (např. blokování/omezení zpracování, provedení opravy, doplnění nebo
                    likvidace/výmaz osobních údajů). Máte též právo na přenositelnost automatizovaně
                    zpracovávaných osobních údajů (tj. získat osobní údaje od správce a předat je jinému
                    správci).Dále máte právo po správci nebo zpracovateli požadovat náhradu majetkové i
                    nemajetkové újmy způsobené zpracováním osobních údajů a právo podat stížnost u Úřadu
        pro ochranu osobních údajů Pplk. Sochora 27, PSČ 170 00, Praha 7, www.uoou.cz.</p>

                    <p>PROTI ZPRACOVÁNÍ OSOBNÍCH ÚDAJŮ PRO ÚČELY PŘÍMÉHO MARKETINGU MŮŽETE KDYKOLI VZNÉST
                    NÁMITKU A VAŠE OSOBNÍ ÚDAJE BUDOU OKAMŽITĚ VYMAZÁNY!PROTI ZPRACOVÁNÍ OSOBNÍCH ÚDAJŮ
                    Z DŮVODU OPRÁVNĚNÝCH ZÁJMŮ SPRÁVCE MŮŽETE KDYKOLI VZNÉST NÁMITKU A OSOBNÍ ÚDAJE
                    BUDOU VYMAZÁNY, pokud správce neprokáže závažné oprávněné důvody pro zpracování,
        které převažují nad VAŠIMI zájmy nebo právy.</p>

                    <p>V souvislosti s výkonem svých práv a v případě jakýchkoli otázek či informací ohledně
                    nakládání s osobními údaji, prosím, kontaktujte nás na tel. +420220397600 nebo
                    písemně na recepce@havas.com.
        Tyto zásady ochrany osobních údajů jsou platné od 29. 7. 2020.</p>
                    <br />
                </div>
            </div>
        </>
    )
}
