import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import _ from 'lodash'
import pin from './assets/pin.svg'
import pinOff from './assets/pinoff.svg'
import pinOn from './assets/pinon.svg'
import Cluster from './assets/cluster.svg'
import destination from './assets/destination.png'
import {AppContext} from './ContextProvider'
import Axios from 'axios'
import InfoWindow from './InfoWindow'
import {renderToString} from 'react-dom/server'
import $ from 'jquery'
import queryString from 'query-string'
import {withCookies} from "react-cookie"
import {compose} from 'recompose'
import urlSlug from 'url-slug'

class Map extends Component {

    static contextType = AppContext

    map = null
    markers = []
    visitedMarkers = []
    message = ''
    infoWindow = null
    locationMarker = null
    filter = []
    markerOpacity = 0
    markerOpacityIncrement = 0.05

    directionsRenderer
    startPart
    endPart
    destinationMarker
    infoWindowDirections

    constructor(props) {
        console.log('INIT MAP')
        super(props)
    }

    async componentDidMount() {
        console.log('MOUNT MAP')

        this.visitedMarkers = this.props.cookies.get('visitedMarkers') || []

        const ctx = this.context
        this.filter = [...ctx.state.filter]

        await this.loadGoogleMapScript()

        // hotfix because google infoWindow needs string as argument and JSX converted to string doesn't contains javascript
        $(document).on('click', 'div#infoWindowClick', () => {
            console.log('CLICK ON DIRECTION')
            const lat = document.getElementById('infoWindowClick').getAttribute('lat')
            const lng = document.getElementById('infoWindowClick').getAttribute('lng')
            this.direction(new window.google.maps.LatLng(lat, lng))
        })
    }

    componentDidUpdate() {
        const ctx = this.context
        if (this.message !== ctx.state.message) {
            this.message = ctx.state.message
            console.log('UPDATE SEARCH')
            fetch(`${process.env.REACT_APP_API}/maps/search/${ctx.state.message}`)
                .then(res =>
                    res.json()
                )
                .then(json => {
                    const latLng = new window.google.maps.LatLng(json[1], json[0])
                    this.map.setZoom(16)
                    this.map.setCenter(latLng)
                })
        }

        if (JSON.stringify(this.filter) !== JSON.stringify(ctx.state.filter)) {
            this.filter = [...ctx.state.filter]
            const fadeInMarkers = []
            this.markers.forEach(marker => {
                if (marker.type === 'marker') {
                    this.setIconForMarker(marker)
                    fadeInMarkers.push(marker)
                }
            })
            this.fadeInMarkers(fadeInMarkers)
        }
    }

    async loadGoogleMapScript() {
        if (window.google !== undefined) {
            return this.initMap()
        }

        const ApiKey = process.env.REACT_APP_GOOGLE_MAPS_KEY

        const script = window.document.createElement('script')
        script.src = `https://maps.googleapis.com/maps/api/js?key=${ApiKey}&language=cs`
        script.async = true
        script.defer = true
        script.onerror = () => {
            window.alert("Selhalo načtení Google mapy.")
        }
        script.addEventListener('load', () => {
            this.initMap()
        })
        window.document.body.appendChild(script)
    }

    async initMap() {
        const map = new window.google.maps.Map(document.getElementById('map'), {
            center: {lat: 49.81749199999999, lng: 15.472962},
            zoom: 16,
            controlSize: 28,
            mapTypeControl: true,
            clickableIcons: false,
            streetViewControl: false,
            //scrollwheel: false,
            options: {
                gestureHandling: 'greedy'
            },
            mapTypeControlOptions: {
                mapTypeIds: ['roadmap', 'satellite', 'hybrid', 'terrain', 'styled_map'],
                style: window.google.maps.MapTypeControlStyle.DEFAULT,
                position: window.google.maps.ControlPosition.TOP_LEFT
            }
        })
        this.map = map

        this.icon = this.createIcon(pin)
        this.iconOn = this.createIcon(pinOn)
        this.iconOff = this.createIcon(pinOff)

        map.mapTypes.set('styled_map', new window.google.maps.StyledMapType(this.props.mapStyle, {name: 'Zelená mapa'}))
        map.setMapTypeId('styled_map')

        const geolocationDiv = document.createElement('div')
        this.geolocationControl(geolocationDiv, map)

        let isUserLocation = true
        let urlPosition
        const params = queryString.parse(this.props.location.search)
        console.log('HERE IN MAP')
        if (params.lat !== undefined && params.lng !== undefined && params.zoom !== undefined) {
            let params = queryString.parse(this.props.location.search)
            urlPosition = new window.google.maps.LatLng(Number(params.lat), Number(params.lng))
            const zoom = Number(params.zoom)
            this.map.setCenter(urlPosition)
            this.map.setZoom(zoom)
            isUserLocation = false
        }

        await this.geoLocateUser(isUserLocation, urlPosition)

        map.addListener('idle', () => {
            this.updateMap()
        })

        map.addListener('click', () => {
            this.closeInfoWindow()
        })
    }

    geolocationControl(controlDiv, map) {
        const firstChild = document.createElement('button')
        firstChild.style.backgroundColor = '#fff'
        firstChild.style.border = 'none'
        firstChild.style.outline = 'none'
        firstChild.style.width = '28px'
        firstChild.style.height = '28px'
        firstChild.style.borderRadius = '2px'
        firstChild.style.boxShadow = '0 1px 4px rgba(0,0,0,0.3)'
        firstChild.style.cursor = 'pointer'
        firstChild.style.marginRight = '8px'
        firstChild.style.padding = '0'
        firstChild.title = 'Vaše poloha'
        controlDiv.appendChild(firstChild)

        var secondChild = document.createElement('div')
        secondChild.style.margin = '5px'
        secondChild.style.width = '18px'
        secondChild.style.height = '18px'
        secondChild.style.backgroundImage = 'url(https://maps.gstatic.com/tactile/mylocation/mylocation-sprite-2x.png)'
        secondChild.style.backgroundSize = '180px 18px'
        secondChild.style.backgroundPosition = '6 6'
        secondChild.style.backgroundRepeat = 'no-repeat'
        firstChild.appendChild(secondChild)

        window.google.maps.event.addListener(map, 'center_changed', function () {
            secondChild.style['background-position'] = '0 0'
        })

        controlDiv.index = 1
        map.controls[window.google.maps.ControlPosition.RIGHT_BOTTOM].push(controlDiv)

        firstChild.addEventListener("click", async () => {
            try {
                const position = await this.getCurrentLocation({
                    enableHighAccuracy: true,
                    timeout: 1000 * 60 * 60,
                    maximumAge: 0
                })
                const userPosition = new window.google.maps.LatLng(position.coords.latitude, position.coords.longitude)
                map.setCenter(userPosition)
                this.updateMap()
            } catch (e) {
                if (e.name === 'GeoPositionError') {
                    window.alert('Nepodařilo se získat Vaši geolokaci.\n Chyba: ' + e.message) //+ ' ' + e.code)
                }
            }
        })
    }

    getCurrentLocation(options) {
        return new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resolve, ({code, message}) =>
                    reject(Object.assign(new Error(message), {name: 'GeoPositionError', code})),
                options)
        })
    }

    async geoLocateUser(showPosition, urlPosition) {
        try {
            const position = await this.getCurrentLocation({
                enableHighAccuracy: true,
                timeout: 5000,
                maximumAge: 0
            })
            const userPosition = new window.google.maps.LatLng(position.coords.latitude, position.coords.longitude)
            const svgAnimated = btoa([
                '<?xml version="1.0"?>',
                '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">',
                '<animate href="#back" attributeName="r" from="5.3" to="10" dur="1.2s" begin="0s" repeatCount="indefinite" fill="freeze" id="circ-anim"/>',
                '<animate href="#back" attributeType="CSS" attributeName="opacity" from="1" to="0" dur="1.2s" begin="0s" repeatCount="indefinite" fill="freeze" id="circ-anim" />',
                '<circle id="back" cx="10" cy="10" r="6.5" stroke-width="1" fill="MediumSeaGreen"/>',
                '<circle class="front" cx="10" cy="10" r="5.5" fill="MediumSeaGreen" />',
                '</svg>'
            ].join('\n'))

            this.locationMarker = new window.google.maps.Marker({
                position: userPosition,
                map: this.map,
                clickable: false,
                icon: {
                    url: 'data:image/svg+xml;charset=UTF-8;base64,' + svgAnimated,
                    anchor: new window.google.maps.Point(25, 25),
                    scaledSize: new window.google.maps.Size(50, 50)
                },
                optimized: false,
                zIndex: 1000
            })
            if (showPosition) {
                this.map.setCenter(userPosition)
            } else {
                this.map.setCenter(urlPosition)
            }
        } catch (e) {
            if (e.name === 'GeoPositionError') {
                window.alert('Nepodařilo se získat Vaši geolokaci. Zkontrolujte, zda ji máte povolenou.\n Chyba: ' + e.message) //+ ' ' + e.code)
            }
        }
    }

    async updateMap() {
        console.log('UPDATE MAP')
        const lat = this.map.getCenter().lat()
        const lng = this.map.getCenter().lng()
        const search = this.props.location.search
        const currentSearch = "?" + new URLSearchParams({
            lat: lat.toString(),
            lng: lng.toString(),
            zoom: this.map.getZoom()
        })
        if (search !== currentSearch) {
            const {pathname} = this.props.location
            this.props.history.replace({
                pathname: pathname,
                search: currentSearch
            })
        }
        await this.props.fetchMarkers(this.map.getBounds(), this.map.getZoom())
        this.addMarkers(this.props.markers)
    }

    addMarkers(markersData) {
        console.log('MARKERS DATA')
        if (this.map == null) return

        if (this.map.getZoom() < 16) {
            const clusters = markersData.filter((value) => {
                return value.pointsData === undefined
            })

            const pointsData = markersData.filter((value) => {
                return value.pointsData !== undefined
            })

            const pointsDataRaw = pointsData.flatMap((val) => {
                return val.pointsData
            })
            clusters.push(...pointsDataRaw)

            markersData = clusters
        }

        if (markersData.length > 0) {
            const redundant = this.markers.filter(val => !markersData.some(data => data._id === val.key))
            redundant.forEach(val => val.setMap(null))
            this.markers = this.markers.filter(val => markersData.some(data => data._id === val.key))

            markersData = markersData.filter(val => !this.markers.some(data => data.key === val._id))
        }
        const newMarkers = []

        markersData.forEach(data => {
            const isMarker = data.count === undefined
            const marker = isMarker ? this.createMarker(data) : this.createCluster(data)
            marker.setMap(this.map)
            this.markers.push(marker)
            newMarkers.push(marker)
        })

        this.fadeInMarkers(newMarkers)
    }

    fadeInMarkers(markers) {
        if (this.markerOpacity <= 1) {
            _.forEach(markers, marker => {
                marker.setOpacity(this.markerOpacity)
            })
            this.markerOpacity += this.markerOpacityIncrement
            setTimeout(() => {
                this.fadeInMarkers(markers)
            }, 20)
        } else {
            this.markerOpacityIncrement = 0.05
            this.markerOpacity = 0
        }
    }

    createCluster(clusterData, zoom) {
        const iconGroup = {
            url: Cluster,
            anchor: new window.google.maps.Point(30, 30),
            scaledSize: new window.google.maps.Size(60, 60)
        }

        let cluster = new window.google.maps.Marker({
            position: new window.google.maps.LatLng(clusterData.lat, clusterData.lng),
            draggable: false,
            icon: iconGroup,
            label: {
                color: '#ffffff',
                fontSize: '12px',
                fontWeight: '900',
                text: String(clusterData.count),
            },
            optimized: false,
            clickable: false,
            zIndex: +new Date,
            key: clusterData._id,
            zoom: zoom,
            type: 'cluster'
        })

        return cluster
    }

    createMarker(markerData) {
        const marker = new window.google.maps.Marker({
            position: new window.google.maps.LatLng(markerData.lat, markerData.lng),
            draggable: false,
            optimized: false,
            clickable: true,
            zIndex: +new Date,
            key: markerData._id,
            cluster: markerData.cluster,
            zoom: 16,
            type: 'marker',
            shadow: 'none'
        })

        this.setIconForMarker(marker)

        marker.addListener('click', async () => {
            this.closeInfoWindow()

            this.infoWindow = new window.google.maps.InfoWindow({
                maxWidth: 250
            })

            const url = `${process.env.REACT_APP_API}/maps/marker/${markerData._id}`
            const {data} = await Axios.get(url)

            if (!this.visitedMarkers.includes(data._id)) {
                this.visitedMarkers.push(data._id)

                const options = {
                    path: "/",
                    maxAge: 31536000
                }

                this.props.cookies.set('visitedMarkers', this.visitedMarkers, options)
            }

            //marker.setIcon(this.iconOn)

            const infoWindow = <InfoWindow marker={data}/>
            this.infoWindow.setContent(renderToString(infoWindow))
            this.infoWindow.open(this.map, marker)
            //this.markerOpacityIncrement = 1
            //this.fadeInMarkers([marker])

            this.setIconForMarker(marker)

            // const slug = urlSlug(data.position.name + '-' + data.position.city)
            // const { pathname } = this.props.location
            // const { history } = this.props
            // history.replace({
            //     search: ''
            // })
            // history.push({
            //     pathname: `${pathname}/misto/${markerData._id}/${slug}`
            // })
            // //const { url, path } = useRouteMatch()
            // console.log(this.props)
            // //  console.log(url)
            // //  console.log(path)
            // //history.push(`/misto/${markerData._id}`)
            // this.props.history.push({
            //     pathname: `${pathname}/misto/${markerData._id}`,
            //     //search: '?query=abc',
            //     state: { detail: markerData._id }
            // })

        })

        return marker
    }

    setIconForMarker(marker) {
        let markerIcon = this.icon

        if (this.visitedMarkers.includes(marker.key)) {
            markerIcon = this.iconOn
        }

        const cluster = marker.cluster
        const types = [cluster.glass, cluster.plastic, cluster.paper, cluster.drinkCarton, cluster.metal, cluster.courtyard]

        for (let i = 0; i < types.length; i++) {
            if (this.filter[i] === true) {
                if (types[i] === false) {
                    markerIcon = this.iconOff
                }
                if (types[i] === true) {
                    markerIcon = this.icon
                    break
                }
            }
        }

        if (cluster.courtyard === true) {
            markerIcon = this.icon
        }

        marker.setIcon(markerIcon)
    }

    createIcon(url) {
        const icon = {
            url: url,
            anchor: new window.google.maps.Point(15, 30),
            scaledSize: new window.google.maps.Size(30, 30)
        }

        return icon
    }

    closeInfoWindow() {
        if (this.infoWindow != null) {
            this.infoWindow.close(this.map)
            this.infoWindow = null
        }
    }

    async direction(markerPosition) {
        try {
            const position = await this.getCurrentLocation({
                enableHighAccuracy: true,
                timeout: 5000,
                maximumAge: 1000 * 60 * 60 * 24
            })
            const mePosition = new window.google.maps.LatLng(position.coords.latitude, position.coords.longitude)
            this.calculateAndDisplayRoute(markerPosition, mePosition)
        } catch (e) {
            if (e.name === 'GeoPositionError') {
                window.alert('Nepodařilo se získat Vaši geolokaci.\n Chyba: ' + e.message) //+ ' ' + e.code)
            }
        }
    }

    calculateAndDisplayRoute(markerPosition, mePosition) {
        if (this.startPart != null) {
            this.clearRoute()
        }

        this.closeInfoWindow()

        this.directionsRenderer = new window.google.maps.DirectionsRenderer({
            suppressMarkers: true,
            polylineOptions: {strokeColor: '#00c000'}
        })
        this.directionsRenderer.setMap(this.map)

        const directionsService = new window.google.maps.DirectionsService()

        directionsService.route(
            {
                origin: mePosition,
                destination: markerPosition,
                travelMode: 'WALKING'
            },

            (response, status) => {
                if (status === 'OK') {
                    const centerPoint = Math.ceil(response.routes[0].legs[0].steps.length / 2)
                    const latLng = new window.google.maps.LatLng(response.routes[0].legs[0].steps[centerPoint].start_location.lat(),
                        response.routes[0].legs[0].steps[centerPoint].start_location.lng())

                    this.distance(mePosition, markerPosition, latLng)

                    this.directionsRenderer.setDirections(response)

                    const startLat = response.routes[0].legs[0].start_location.lat()
                    const startLng = response.routes[0].legs[0].start_location.lng()
                    const endLat = response.routes[0].legs[response.routes[0].legs.length - 1].end_location.lat()
                    const endLng = response.routes[0].legs[response.routes[0].legs.length - 1].end_location.lng()

                    const lineSymbol = {
                        path: 'M 0,0 0,1',
                        strokeOpacity: 1,
                        strokeColor: '#00c000',
                        scale: 4
                    }

                    const startCoordinates = [
                        {lat: startLat, lng: startLng},
                        {lat: mePosition.lat(), lng: mePosition.lng()}
                    ]

                    this.startPart = new window.google.maps.Polyline({
                        path: startCoordinates,
                        strokeOpacity: 0.0,
                        geodesic: true,
                        strokeColor: '#00c000',
                        icons: [{
                            icon: lineSymbol,
                            fillColor: '#00c000',
                            offset: '12px',
                            repeat: '14px'
                        }]
                    })

                    this.startPart.setMap(this.map)

                    const endCoordinates = [
                        {lat: endLat, lng: endLng},
                        {lat: markerPosition.lat(), lng: markerPosition.lng()}
                    ]

                    this.endPart = new window.google.maps.Polyline({
                        path: endCoordinates,
                        geodesic: true,
                        strokeOpacity: 0.0,
                        strokeColor: '#00c000',

                        icons: [{
                            icon: lineSymbol,
                            fillColor: '#00c000',
                            offset: '12px',
                            repeat: '14px'
                        }],
                    })

                    this.endPart.setMap(this.map)

                    const destinationImage = new window.google.maps.MarkerImage(
                        destination,
                        new window.google.maps.Size(16, 16),
                        new window.google.maps.Point(0, 0),
                        new window.google.maps.Point(8, 8))

                    this.destinationMarker = new window.google.maps.Marker({
                        position: endCoordinates[1],
                        map: this.map,
                        title: " ",
                        label: '',
                        icon: destinationImage,
                        optimized: false,
                        zIndex: 89
                    });
                } else {
                    window.alert('Nepodařilo se vykreslit trasu.')
                }
            }
        )
    }

    clearRoute() {
        this.directionsRenderer.setMap(null)
        this.destinationMarker.setMap(null)
        this.destinationMarker = null
        this.startPart.setMap(null)
        this.startPart = null
        this.endPart.setMap(null)
        this.endPart = null
    }

    distance(from, to, latLng) {
        const service = new window.google.maps.DistanceMatrixService()

        service.getDistanceMatrix({
                origins: [from],
                destinations: [to],
                travelMode: window.google.maps.TravelMode.WALKING,
                unitSystem: window.google.maps.UnitSystem.METRIC,
                avoidHighways: false,
                avoidTolls: false,
            }, (response, status) => {
                if (status !== "OK") {
                    console.log("Error was: " + status)
                } else {
                    const distance = response.rows[0].elements[0].distance.text
                    const duration = response.rows[0].elements[0].duration.text

                    if (this.infoWindowDirections != null) {
                        this.infoWindowDirections.close(this.map)
                    }

                    this.infoWindowDirections = new window.google.maps.InfoWindow({
                        maxWidth: 250
                    })

                    window.google.maps.event.addListener(this.infoWindowDirections, 'closeclick', () => {
                        if (this.startPart != null) {
                            this.clearRoute()
                        }
                    })

                    this.infoWindowDirections.setContent(`<div id="direction">${distance}<br>${duration}</div>`)
                    this.infoWindowDirections.setPosition(latLng)
                    this.infoWindowDirections.open(this.map)
                }
            }
        )
    }

    render() {
        return (
            <div id="map-wrapper">
                <div id="map"/>
            </div>
        )
    }

}

export default compose(
withRouter,
withCookies
)(Map)
