import React, {useState, useEffect, useContext} from 'react'
import ContextProvider, {AppContext} from '../ContextProvider'
import Button from '@material-ui/core/Button'
import {makeStyles, withStyles} from '@material-ui/core/styles'
import PinGreen from "../assets/pinGreen.svg"
import PinGray from '../assets/pinGray.svg'
import PinRed from '../assets/pinRed.svg'
import {useHistory, useLocation, useParams} from 'react-router-dom'
import axios from 'axios'

const useStyles = makeStyles({
    greenButton: {
        background: '#32702F',
        border: 1,
        borderRadius: 3,
        color: '#FFFFFF',
        height: 48,
        width: '100%',
        '&:hover': {
            background: 'green',
        },
        margin: '0 0 5px 0',
    },
    // green: {
    //     background: '#32702F',
    // },
    // green: {
    //     background: '#32702F',
    // }
})

// border: 2px solid #6BB03A;
// background-color: #32702F;

export default (props) => {
    const defOptions = require('../staticData/filter.json')
    const classes = useStyles()
    const context = useContext(AppContext)
    const [marker, setMarker] = useState(null)
    const [options, setOptions] = useState(defOptions)
    const location = useLocation();

    const history = useHistory()

    const { id } = useParams()

    useEffect(() => {
        const opt = [...defOptions]
        let url = `${process.env.REACT_APP_API}/api/markers/${id}`
        console.log(url)
        axios.get(url)
            .then(response => {
                const marker = response.data
                opt[0].isActive = marker.cluster.glass
                opt[1].isActive = marker.cluster.plastic
                opt[2].isActive = marker.cluster.paper
                opt[3].isActive = marker.cluster.drinkCarton
                opt[4].isActive = marker.cluster.metal
                opt[5].isActive = marker.cluster.courtyard
                setOptions(opt)
                setMarker(marker)
            })
            .catch((error) => {
            })

        return () => {
            context.setPlace(context.state.place, ContextProvider.ActionsEnum.BACK)
        }
    }, [])

    const handleOptions = (index) => {
        const opt = [...options]
        opt[index].isActive = !opt[index].isActive
        setOptions(opt)
    }

    const onSubmit = async () => {

        const objKeys = Object.keys(options)
        let check = false
        objKeys.forEach(key => {
            const value = options[key].isActive
            if (value === true) {
                check = true
            }
        })
        if (check === false) {
            alert("Musíte označit aspoň jeden druh kontejneru")

            return
        }

        const lat = context.state.lat
        const lng = context.state.lng

        marker.lat = lat
        marker.lng = lng
        marker.cluster = {
            "paper": options[0].isActive,
            "plastic": options[1].isActive,
            "glass": options[2].isActive,
            "drinkCarton": options[3].isActive,
            "metal": options[4].isActive,
            "courtyard": options[5].isActive
        }

        let url = `${process.env.REACT_APP_API}/api/markers`
        await axios.put(url, marker)
        history.goBack()
    }

    const onDelete = async () => {
        let url = `${process.env.REACT_APP_API}/api/markers/report/${marker._id}`
        await axios.post(url)
        history.goBack()
    }

    return (
        <div id="markers">
            {marker == null &&
            <div/>
            }
            {marker != null &&
            <>
                <div id="detailMarker">
                    <p>{marker.position.name}, {marker.position.city}</p>
                    <img src={marker.isHidden ? PinGray : marker.isVerified ? PinGreen : PinRed}/>
                    <div className="clearfix"/>
                </div>
                <div id="panelFilter" className="filterAdmin">

                    <ul>
                        {options.map((item, index) => (
                            <li key={item.index}
                                onClick={() => {
                                    handleOptions(index)
                                }}
                                className={item.isActive ? 'active' : 'noActive'}>
                                {item.title}
                            </li>
                        ))}
                    </ul>
                </div>
                <Button variant="contained" className={classes.greenButton} onClick={() => onSubmit()}>Uložit</Button>
                <Button variant="contained" className={classes.greenButton} onClick={() => onDelete()}>Odstranit</Button>
                <Button variant="contained" className={classes.greenButton} onClick={() => history.goBack()}>ZPĚT</Button>
            </>
            }
        </div>
    )
}
