import React, {Component} from 'react'
import Axios from 'axios'
import Navigation from './Navigation'
import Cookies from './cookies/Cookies'
import Map from './Map'
import {
    Switch,
    Route,
    Link
} from "react-router-dom"
import './css/App.css'
import Layout from './Layout'
import CssBaseline from '@material-ui/core/CssBaseline'
import Auth from "./Auth";

export default class Public extends Component {

    constructor(props) {
        super(props)

        this.state = {
            markers: []
        }

        this.fetchMarkers = this.fetchMarkers.bind(this)
    }

    componentDidMount() {
        console.log('PUBLIC')
    }

    componentDidUpdate() {
        console.log('PUBLIC')
    }

    async fetchMarkers(bounds, zoom) {
        console.log('FETCH')
        const southWest = bounds.getSouthWest()
        const northEast = bounds.getNorthEast()
        console.log(process.env.REACT_APP_API)
        const {data} = await Axios.get(`${process.env.REACT_APP_API}/maps/markers`, {
            params: {
                southWestLng: southWest.lng(),
                southWestLat: southWest.lat(),
                northEastLng: northEast.lng(),
                northEastLat: northEast.lat(),
                zoom: zoom
            }
        })
        this.setState({
            markers: data
        })
    }

    render() {
        const siteData = require('./staticData/site.json')
        console.log('PUBLIC')
        return (
            <>
                <CssBaseline/>
                <Layout>
                    <Route exact path="/">
                        {/*<Route exact path={["/", "/misto/:id"]}>*/}
                        <Navigation siteData={siteData}/>
                    </Route>
                    {/*<Route path = {'/prihlaseni'}>*/}
                    {/*    <Auth />*/}
                    {/*</Route>*/}
                    {/* <Route> */}
                    <Map
                        mapStyle={require('./staticData/styledMapType.json')}
                        markers={this.state.markers}
                        fetchMarkers={this.fetchMarkers}
                    />
                    {/* </Route> */}

                </Layout>
                {/*<Route path={'/prihlaseni'}>*/}
                {/*    <Auth/>*/}
                {/*</Route>*/}
                <Cookies/>
            </>
        )
    }
}
