/* eslint-disable */

import React from 'react'
import ReactDOM from 'react-dom'
import App from "./App"
import { BrowserRouter } from "react-router-dom"
import reportWebVitals from './reportWebVitals'
import { CookiesProvider } from "react-cookie"
import ContextProvider from "./ContextProvider";

ReactDOM.render(
    <BrowserRouter>
        <ContextProvider>
            <CookiesProvider>
                <App />
            </CookiesProvider>
        </ContextProvider>
    </BrowserRouter>
    ,
  document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
