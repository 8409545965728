import React, {useState} from 'react'
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import {makeStyles} from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Logo from '../assets/logo.svg'
import axios from "axios";
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';
import {CircularProgress} from "@material-ui/core";
import {AlertTitle} from "@material-ui/lab";

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'© '}
            <Link color="inherit" href="https://material-ui.com/">
                kamtridit.cz
            </Link>{' '}
            {new Date().getFullYear()}
        </Typography>
    )
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: 'black',
        paddingTop: 5,
        paddingBottom: 0,
        paddingRight: 30,
        paddingLeft: 30,
        width: '100%',
        marginBottom: 30,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        height: 50,
    },
    alert: {
        width: '100%',
        marginTop: theme.spacing(2),
    },
    loader: {
        marginTop: 20,
    },
}))

export default function Password() {
    const classes = useStyles()

    const [user, setUser] = useState({email: ''})
    const [error, setError] = useState({email: false})
    const [openAlert, setOpenAlert] = useState(false)
    const [errorMessage, setErrorMessage] = useState('Chyba. Zkuste prosim pozdeji.')
    const [loading, setLoading] = useState(false)
    const [submitted, setSubmitted] = useState(false)

    const handleSubmit = (e) => {
        e.preventDefault()
        if (!handleValidity()) {
            return
        }
        setLoading(true)

        axios.post(`${process.env.REACT_APP_API}/api/auth/password`,
            {email: user.email}
        )
            .then(response => {
                setLoading(false)
                setSubmitted(true)
            })
            .catch(error => {
                setLoading(false)
                if (error.response.data.message) {
                    setErrorMessage(error.response.data.message)
                }
                setOpenAlert(true)
            })
    }

    const handleError = (field) => {
        if (field === 'email') {
            setError({...error, email: !emailIsValid(user.email)})
        }
    }

    const handleValidity = () => {
        return (emailIsValid(user.email))
    }

    const emailIsValid = (email) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
    }

    const valid = handleValidity()

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <div className={classes.paper}>
                <div className={classes.avatar}>
                    <img src={Logo}/>
                </div>
                <Typography component="h1" variant="h5">
                    RESETOVAT HESLO
                </Typography>
                {loading === true &&
                <CircularProgress className={classes.loader}/>
                }
                {submitted === true &&
                <Alert severity="success" className={classes.alert}>
                    <AlertTitle>E-mail odeslan</AlertTitle>
                    Prosim zkontrolujte e-mail pro reset hesla.
                </Alert>
                }
                {submitted === false && loading === false &&
                <>
                    <Collapse in={openAlert} className={classes.alert}>
                        <Alert severity="error"
                               action={
                                   <IconButton
                                       aria-label="close"
                                       color="inherit"
                                       size="small"
                                       onClick={() => {
                                           setOpenAlert(false)
                                       }}
                                   >
                                       <CloseIcon fontSize="inherit"/>
                                   </IconButton>
                               }
                        >
                            {errorMessage}
                        </Alert>
                    </Collapse>
                    <form className={classes.form} onSubmit={handleSubmit}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="E-mail"
                            name="email"
                            value={user.email}
                            onChange={e => {
                                setUser({...user, email: e.target.value})
                            }}
                            onBlur={(e) => {
                                handleError(e.target.name)
                            }}
                            error={error.email}
                            helperText={error.email ? 'Nevalidní email' : ' '}
                            autoFocus
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            disabled={!valid}
                            color="primary"
                            className={classes.submit}
                        >
                            ODESLAT
                        </Button>
                    </form>
                    <Box mt={8}>
                        <Copyright/>
                    </Box>
                </>
                }
            </div>


        </Container>
    )
}