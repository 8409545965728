import React, {useContext} from 'react'
import {AppContext} from '../ContextProvider'
import PanelNavigation from '../PanelNavigation'
import Hidden from '@material-ui/core/Hidden'
import sklo from '../assets/sklo.svg'
import plasty from '../assets/plasty.svg'
import papir from '../assets/papir.svg'
import karton from '../assets/napojovekartony.svg'
import kovy from '../assets/kovy.svg'
import dvur from '../assets/sbernydvur.svg'

export default ({id}) => {
    const filterData = require('../staticData/filter.json')
    const filterDataOne = filterData.slice(0, 3)
    const filterDataTwo = filterData.slice(3, 6)
    const images = [sklo, plasty, papir, karton, kovy, dvur]
    const context = useContext(AppContext)

    const onFilter = (index, value) => {
        let val = context.state.filter
        val[index] = !value
        context.setFilter(val)
    }

    return (
        <>
            <PanelNavigation id={id} panel={'panelFilter'}/>
            <div id="panelFilter" className="panelWrapper">
                <Hidden smDown>
                    <ul>
                        {filterData.map((item, index) => (
                            <li key={item.index}
                                onClick={() => {
                                    onFilter(index, context.state.filter[index])
                                }}
                                className={context.state.filter[index] ? 'active' : 'noActive'}>
                                {item.title}
                            </li>
                        ))}
                    </ul>
                </Hidden>
                <Hidden mdUp>
                    <table id="tbFilter">
                        <tr>
                            <td className="half">
                                <table>
                                    <tbody>
                                    {filterDataOne.map((item, index) => (
                                        <tr onClick={() => {
                                            onFilter(index, context.state.filter[index])
                                        }} key={item.index}>
                                            <td className="image"><img src={images[index]}/></td>
                                            <td className={context.state.filter[index] ? 'active' : 'noActive'}>{item.title}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </td>
                            <td className="half">
                                <table>
                                    <tbody>
                                    {filterDataTwo.map((item, index) => (
                                        <tr onClick={() => {
                                            onFilter(index + 3, context.state.filter[index + 3])
                                        }} key={item.index}>
                                            <td className="image"><img src={images[index + 3]}/></td>
                                            <td className={context.state.filter[index + 3] ? 'active' : 'noActive'}>{item.title}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </table>
                </Hidden>
            </div>
        </>
    )
}
