import React, { Component } from 'react'
import {Link, Route, Switch, withRouter} from "react-router-dom"
import './css/App.css'
import Public from './Public'
import Admin from './Admin'
import Signup from './user/Signup'
import Login from './user/Login'
import Password from './user/Password'
import Confirmation from "./user/Confirmation"
import ResetPassword from "./user/ResetPassword"

export default class App extends Component {

  render() {
    if (window.location.pathname.substring(0,9) === '/uzivatel') {
      return (
          <Switch>
            <Route exact path="/uzivatel/registrace" component={Signup}/>
            <Route exact path="/uzivatel/prihlaseni" component={Login}/>
            <Route exact path="/uzivatel/zapomenute-heslo" component={Password}/>
            <Route exact path="/uzivatel/potvrzeni-registrace" component={Confirmation}/>
            <Route exact path="/uzivatel/nove-heslo" component={ResetPassword}/>
          </Switch>
      )
    }
    if (window.location.pathname.substring(0,6) === '/admin') {
      return <Admin />
    } else {
      return <Public />
    }
  }
}
