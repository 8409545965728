import React from 'react'
import GooglePlay from '../assets/googleplay.svg'
import Apple from '../assets/appstore.svg'
import PanelNavigation from '../PanelNavigation'

export default ({ id }) => {
    return (
        <>
            <PanelNavigation id={id} panel={'panelUsers'} />
            <div id="panelUsers" className="panelWrapper">
                <p>Objevili jste kontejnery na tříděný
                odpad nebo sběrný dvůr, které nejsou na mapě vyznačené nebo jsou zobrazené na nesprávném místě?
    Pomozte nám je lokalizovat.</p>
                <p><i>Děkujeme!</i></p>
                <p>Zapojit se můžete prostřednictvím aplikace, která je ke stažení zde:</p>
                <br />
                <a href=""><img src={GooglePlay} /></a>
                <a href=""><img src={Apple} /></a>
            </div>
        </>
    )
}
