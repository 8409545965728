import React, { useState, useEffect } from 'react'
import {useHistory, useLocation, useParams} from 'react-router-dom'
import Axios from 'axios'
import PanelNavigation from '../PanelNavigation'

export default ({ id }) => {
    const [groups, setGroups] = useState({ groups: Object })
    const [alphabet, setAlphabet] = useState({ alphabet: [] })
    const [loading, setLoading] = useState({ loading: false })
    const history = useHistory()

    useEffect(async () => {
        const { data } = await Axios(
            'https://www.samosebou.cz/wp-json/samosebou/kampatri'
        )
        const groupByAlphabet = groupBy('character')
        const groups = groupByAlphabet(data)
        const alphabet = Object.keys(groups)

        setGroups(groups)
        setAlphabet(alphabet)
        setLoading(true)
    }, [])

    useEffect(() => {
        //console.log(history)
    })

    const groupBy = (key) => (array) =>
        array.reduce((objectsByKeyValue, obj) => {
            const value = obj[key]
            objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj)
            return objectsByKeyValue
        }, {})

    const onLetterClick = (item) => {
        document.getElementById(`page-${item}`).scrollIntoView(true)
    }

    return (
        <>
        <PanelNavigation id={id} panel={'panelInfo'} />
        <div id="panelInfo" className="panelWrapper">
            {loading === false &&
                <div />
            }
            {loading === true &&
                <>
                    <ul id="alphabet">
                        {alphabet.map(item => (
                            // <li key={item}><a href={`#page-${item}`}>{item}</a></li>
                            <li key={item}><a href={void(0)} onClick={() => onLetterClick(item)}>{item}</a></li>
                        ))}
                    </ul>
                    <section>
                        {Object.keys(groups).map((key, index) => (
                            <div key={index} id={`page-${key}`} className="anchor">
                                <div className="letter">{key}</div>
                                <div className="hr" />
                                {groups[key].map((item, i) => (
                                    <div className="infoItem" key={`${key}-${i}`}>
                                        <span>{item['name']}</span> - {item['copy']}
                                        {item['link_samosebou'].length > 0 &&
                                            <><br /><a href={item['link_samosebou']} target="_blank">více</a></>
                                        }
                                    </div>
                                ))}
                            </div>
                        ))}
                    </section>
                </>
            }
        </div>
        </>
    )
}
