import React, { useState } from 'react'
import axios from 'axios'

import { useCookies } from "react-cookie"

function Login(props) {
    const [loading, setLoading] = useState(false)
    const username = useFormInput('')
    const password = useFormInput('')
    const [error, setError] = useState(null)

    const [cookies, setCookie] = useCookies(['auth-token'])

    const handleLogin = () => {
        setError(null);
        setLoading(true);
        axios.post(`${process.env.REACT_APP_API}/api/auth/loginAdmin`,
            { email: username.value, password: password.value },
            {
                //headers: {'device': 'browser'},
                withCredentials: true,
                //credentials: 'include'
        }
        )
            .then(response => {
                const options = {
                    path: "/",
                    maxAge: 3600,
                    //httpOnly: true
                }
                setCookie('auth-token', response.data.token, options)
                setLoading(false)
                window.location.href = "/admin"
            })
            .catch(error => {
                setLoading(false)
                if (error.response.data.message) {
                    setError(error.response.data.message)
                }
                else setError("Chyba. Zkuste prosim pozdeji.")
            })
    }

    return (
        <div>
            <div>
                <input type="text" {...username} autoComplete="new-password" placeholder="E-mail" required />
            </div>
            <div style={{ marginTop: 10 }}>
                <input type="password" {...password} autoComplete="new-password" placeholder="Heslo" required/>
            </div>
            {error && <><small style={{ color: 'red' }}>{error}</small><br /></>}<br />
            <input type="button" value={loading ? 'Moment...' : 'Odeslat'} onClick={handleLogin} disabled={loading} /><br /><br />
            <input type="button" value="Zavrit" onClick={props.handleClose} disabled={loading} />
        </div>
    )
}

const useFormInput = initialValue => {
    const [value, setValue] = useState(initialValue)

    const handleChange = e => {
        setValue(e.target.value)
    }
    return {
        value,
        onChange: handleChange
    }
}

export default Login
